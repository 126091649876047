<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div *ngIf="company" class="row px-2">
  <div class="col-12">

    <div class="row mb-3">
      <div class="col-12">
        <button type="button" class="btn btn-link" (click)="goBack()" tabindex>
          <!-- FIXME:: Convert to new angular fontawesome -->
          <span class="fa fa-chevron-left"></span>
          &nbsp;Return to Search Results
        </button>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h3>{{ company.companyName }}</h3>
          </div>
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-2">
                <strong>Address:</strong>
              </div>
              <div class="col-4">
                <div id="companyAddress1">
                  {{ company.streetAddress1 }}<br/>
                  <p *ngIf="company.streetAddress2">{{ company.streetAddress2 }}<br/></p>
                  {{ company.city }}, {{ company.state }} {{ company.postalCode }}
                </div>
              </div>
              <div class="col-3">
                <strong>State of Domicile:</strong>
              </div>
              <div class="col-3">
                {{ company.stateOfDomicile }}
              </div>
            </div>

            <div class="row">
              <div class="col-2">
                <strong>CoCode:</strong>
              </div>
              <div class="col-4">
                {{ company.companyCoCode }}
              </div>
              <div class="col-3">
                <strong>Statement Types:</strong>
              </div>
              <div class="col-3">
                <div *ngFor="let compStatementType of company.statementTypes">
                  {{ compStatementType.value }}
                </div>
              </div>
            </div>

            <div *ngIf="loadingProducts" class="row mb-3">
              <div class="col-12">
                <p>Loading Products...
                  <!-- FIXME:: Convert to new angular fontawesome -->
                  <span class="fa fa-spinner fa-spin fa-lg"></span>
                </p>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <!-- TODO:: Do we want to make this be an accordion for real? -->
                <div id="accordion" role="tablist" *ngIf="productDataKeys">
                  <div class="card company-detail" *ngFor="let productDataMap of productDatakeyWrappers">
                    <div class="card-header card-header-clickable" role="tab" [id]="productDataMap.title | trim"
                         data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + (productDataMap.title | trim)"
                         (click)="showProductData(productDataMap)">
                      <h5 class="mb-1">
                        <a data-bs-toggle="collapse"
                           class="collapsed"
                           href="#collapse{{productDataMap.title | trim}}"
                           aria-expanded="false"
                           attr.aria-controls="collapse{{productDataMap.title | trim}}">
                          {{ productDataMap.title }}
                          <!-- FIXME:: Convert to new angular fontawesome -->
                          <span class="fa fa-caret-up float-end"></span>
                          <!-- FIXME:: Convert to new angular fontawesome -->
                          <span class="fa fa-caret-down float-end" title="See More"></span>
                        </a>
                      </h5>
                      <ng-container
                        *ngIf="productDataMap.shouldShowAddToCocodeButton && !productDataMap.isCocodeOnUsersCocodeList">
                        <button type="button" class="btn btn-sm btn-primary float-end"
                                [disabled]="!productDataMap.isAddToCocodeEnabled"
                                (click)="addCocodeToCocodeList(productDataMap)">Add
                          to {{ productDataMap.title.substring(0, 4) }}
                          cocode list
                        </button>
                      </ng-container>
                      <ng-container
                        *ngIf="productDataMap.shouldShowAddToCocodeButton && productDataMap.isCocodeOnUsersCocodeList">
                        <span class="float-end">
                          <!-- FIXME:: Convert to new angular fontawesome -->
                          <span class="fa fa-check-circle icon-green"></span>&nbsp;Added
                          to {{ productDataMap.title.substring(0, 4) }}
                          Cocode List</span>
                      </ng-container>

                    </div>
                    <div id="collapse{{productDataMap.title | trim}}"
                         class="collapse"
                         role="tabpanel"
                         attr.aria-labelledby="{{productDataMap.title | trim}}"
                         data-bs-parent="#accordion">

                      <div class="row">
                        <div class="col-12">
                          <div *ngIf="loadingActiveProductDatas" class="text-center">
                            <!-- FIXME:: Convert to new angular fontawesome -->
                            <span class="fa fa-spinner fa-spin fa-lg"></span>
                          </div>

                          <div class="alert alert-warning"
                               *ngIf="!loadingActiveProductDatas  && (!productDataMap.productDatas || productDataMap.productDatas.length === 0) && !productDataMap.error">
                            No Products for this Filing Period
                          </div>

                          <div class="alert alert-danger"
                               *ngIf="!loadingActiveProductDatas  && (!productDataMap.productDatas || productDataMap.productDatas.length === 0) && productDataMap.error">
                            An error occurred when fetching products for this Filing Period
                          </div>

                          <div *ngIf="productDataMap.productDatas && productDataMap.productDatas.length > 0">
                            <div class="card-body" *ngFor="let productData of productDataMap.productDatas">
                              <div class="card">
                                <div class="card-body">
                                  <div class="row justify-content-between">
                                    <div class="col-lg-4"
                                         [ngClass]="{'col-lg-4': productData.sellable , 'col-lg-8': !productData.sellable}">
                                      <p *ngIf="productData.productDataKey.submissionFilingPeriod !== 'Annual'">
                                        <strong>{{ productData.productDataKey.statementYear }} {{ productData.productDataKey.submissionFilingPeriod }}
                                          Filings</strong>
                                        {{ !productData.sellable ? ' - This information is unavailable. Visit ‘Help’ for more details.' : '' }}
                                      </p>
                                      <p
                                        *ngIf="productData.productDataKey.submissionFilingPeriod === 'Annual' && productData.productDataKey.isKey">
                                        <strong>{{ productData.productDataKey.statementYear }}
                                          Key {{ productData.productDataKey.submissionFilingPeriod }} Filings
                                        </strong> {{ !productData.sellable ? ' - This information is unavailable. Visit ‘Help’ for more details.' : '' }}
                                      </p>
                                      <p
                                        *ngIf="productData.productDataKey.submissionFilingPeriod === 'Annual' && !productData.productDataKey.isKey">
                                        <strong>{{ productData.productDataKey.statementYear }}
                                          Non-Key {{ productData.productDataKey.submissionFilingPeriod }} Filings
                                        </strong> {{ !productData.sellable ? ' - This information is unavailable. Visit ‘Help’ for more details.' : '' }}
                                      </p>
                                    </div>
                                    <div class="col-lg-3">
                                      <div class="float-lg-end"
                                           *ngIf="productData.sellable && (userRole !== 'ROLE_SPECIAL' && userRole !== 'ROLE_ALL_ACCESS')">
                                        {{ productData.priceUsd | currency:'USD':true:'1.2-2' }}
                                        <a *ngIf="productsInTransition.indexOf(productData) === -1 && doNotCharge"
                                           (click)="doNotChargeDownloadFile(productData)" title="Download Product">
                                          <!-- FIXME:: Convert to new angular fontawesome -->
                                          <span class="fa fa-2x fa-download"></span></a>
                                        <a
                                          *ngIf="!hasProductInCart(productData) && productsInTransition.indexOf(productData) === -1 && !doNotCharge"
                                          (click)="!isCartLimitMet() && addToCart(productData)"
                                          [class.disabled]="isCartLimitMet()">
                                          <!-- FIXME:: Convert to new angular fontawesome -->
                                          <span class="fa fa-2x fa-cart-plus" title="Add to Cart"></span></a>
                                        <!-- FIXME:: Convert to new angular fontawesome -->
                                        <span *ngIf="productsInTransition.indexOf(productData) > -1"
                                              class="fa fa-2x fa-spinner fa-spin"></span>
                                        <a
                                          *ngIf="hasProductInCart(productData) && productsInTransition.indexOf(productData) === -1 && !doNotCharge"
                                          (click)="removeFromCart(productData)">
                                          <!-- FIXME:: Convert to new angular fontawesome -->
                                          <span class="fa fa-2x fa-trash"
                                                title="Remove Item from Cart"></span></a>
                                      </div>
                                      <div class="float-lg-end" *ngIf="!productData.sellable">
                                        <span class="fa-stack"
                                              title="This item cannot be added to your cart. See HELP for more details.">
                                          <!-- FIXME:: Convert to new angular fontawesome -->
                                          <span class="fa fa-2x fa-cart-plus fa-stack-1x" title="Add to Cart"></span>
                                          <!-- FIXME:: Convert to new angular fontawesome -->
                                          <span class="fa fa-2x fa-ban fa-stack-2x text-danger"></span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12">
                                      <table class="table borderless company-detail"
                                             aria-describedby="companySearchResultsDetailTable">
                                        <thead>
                                        <tr>
                                          <th scope="col" class="c-35">File Name</th>
                                          <th scope="col" class="c-30">Statement Type</th>
                                          <th scope="col" class="c-10">Version</th>
                                          <th scope="col" class="c-15">Date Filed</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr *ngFor="let statementFile of productData.statementFiles"
                                            title="{{statementFile.infoNotSellable ? 'This item cannot be added to your cart. See HELP for more details.' : ''}}">
                                          <td
                                            [class.non-sellable-statement]="statementFile.infoNotSellable">{{ statementFile.submissionFilingType }}
                                          </td>
                                          <td
                                            [class.non-sellable-statement]="statementFile.infoNotSellable">{{ statementFile.statementType | titlecase }}
                                          </td>
                                          <td
                                            [class.non-sellable-statement]="statementFile.infoNotSellable">{{ statementFile.submissionVersionType }}
                                          </td>
                                          <td
                                            [class.non-sellable-statement]="statementFile.infoNotSellable">{{ statementFile.dateFiled | date:'MM/dd/yyyy' }}
                                          </td>
                                        </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO:: Consolidate Loading Modals -->
<div bsModal #loadingModal="bs-modal" class="modal" id="loadingModal" tabindex="-1" role="dialog"
     [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>{{ loadingModalText }}</strong></h5>
      </div>
      <div class="modal-body">
        <!-- FIXME:: Convert to new angular fontawesome -->
        <span class="fa fa-spinner fa-spin fa-2x"></span>
      </div>
    </div>
  </div>
</div>

<!-- TODO:: Consolidate Loading Modals -->
<div bsModal #cartIsFullModal="bs-modal" class="modal" id="cartIsFullModal" tabindex="-1" role="dialog"
     [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><strong>Full Cart</strong></h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="cartIsFullModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Your cart is full. Please complete this order.
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="continueToCheckout()" data-bs-dismiss="modal">
          CONTINUE TO PAYMENT
        </button>
      </div>
    </div>
  </div>
</div>
