/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TrimPipe } from './pipes/trim.pipe';
import { NoPoBoxDirective } from './validators/no-po-box.directive';
import { OneOfDirective } from './validators/one-of.directive';
import { StandardModalComponent } from './modals/standard-modal/standard-modal.component';

@NgModule( {
  imports: [ CommonModule ],
  declarations: [
    TrimPipe,
    NoPoBoxDirective,
    OneOfDirective,
    StandardModalComponent,
  ],
  exports: [
    CommonModule,
    TrimPipe,
    FormsModule,
    NoPoBoxDirective,
  ],
} )
export class SharedModule {
}
