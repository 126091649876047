/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BannerInput } from '../../model/customer/bannerInput';
import { InsdataCustomerService } from '../../service/insdata-customer.service';
import { OktaAuthService } from '../../service/okta-auth.service';

@Component( {
  selector: 'app-landing',
  templateUrl: './home.component.html',
  styleUrls: [ './home.component.scss' ],
} )
export class HomeComponent implements OnInit {
  isAuthenticated$ = this.oktaAuthService.isAuthenticated$;
  bannerInfo: BannerInput;

  constructor(
    private readonly oktaAuthService: OktaAuthService,
    private readonly router: Router,
    public readonly customerService: InsdataCustomerService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.isAuthenticated$.subscribe( async ( isAuthenticated: boolean ) => {
      if ( isAuthenticated ) {
        this.router.navigate( [ 'home' ] );
      }
    } );

    this.customerService.bannerInfo$.subscribe( ( data ) => {
      this.bannerInfo = data;
    } );
  }

}
