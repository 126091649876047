<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div class="d-flex flex-column min-vh-100 w-100">
  <app-banner></app-banner>
  <app-header></app-header>
  <ng-container *ngIf="isAuthenticated$ | async; else notAuthenticated">
    <div class="flex-grow-1 w-100">
      <app-insdata-message></app-insdata-message>
      <router-outlet></router-outlet>
    </div>
  </ng-container>
  <app-footer></app-footer>
</div>

<ng-template #notAuthenticated>
  <div class="flex-grow-1 w-100">
    <div class="card mt-2">
      <p class="card-body">You are not currently logged in. You may use any existing NAIC username with associated
        password after selecting Login, or select Signup if you need to create an account. </p>
    </div>
  </div>
  
  <!-- FIXME:: Move inline style to style sheet -->
  <div *ngIf="bannerInfo.bannerStatus"
       style="margin: auto; width: 100%; display: flex; justify-content: center; align-content: center; padding-top: 4rem ">
    <div class="units my-center">
      <span class="my-card fa fa-wrench fa-5x fa-inverse goldBack"></span><br>
      <span class="my-card fa fa-wrench fa-5x fa-inverse goldBack"></span>
    </div>
    <div class="mntNoticeDiv">
      <h3>SERVICE INTERRUPTION NOTICE:</h3><br>
      <p>{{ bannerInfo.bannerText }}</p><br><br>
    </div>
  </div>
</ng-template>
