<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div class="row">
  <app-ncui-header
    [hideAppHeader]="true"
    [appTitle]="header.appTitle"
    [appSubtitle]="header.appSubtitle"
    [appLogo]="header.appLogo"
    [loggedInUser]="isAuthenticated && firstName ? {name: firstName} : null"
    [dropdownMenuItems]="getLoginSignUpOptions()"
    [realm]="realm || 'prod'"
    [helpModalHeaderText]="header.helpModalHeaderText"
    [helpModalContentHtml]="header.helpModalContentHtml"
    [aboutModalComponentListItems]="header.aboutModalComponentListItems"
    (logout)="logout()"
    (dropdownMenuItemClick)="clickLoginOption()"></app-ncui-header>
</div>

<div class="row">
  <nav class="navbar navbar-expand-md second-bar">

    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navRoutingLinks"
              aria-controls="navRoutingLinks" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-brand">
        <img class="brandmark" ngSrc="assets/images/insdata-logo-white-sm.png" alt="InsData Logo" height="29"
             width="168">
      </div>
      <div class="collapse navbar-collapse" *ngIf="!isAuthenticated || isOnAgreement()">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link">&nbsp;</a>
          </li>
        </ul>
      </div>
      <div class="collapse navbar-collapse" id="navRoutingLinks"
           *ngIf="isAuthenticated && isAllowedForIndustry && !isOnAgreement()">
        <ul class="navbar-nav me-auto">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['companySearch']" id="company-search-link">Company Search</a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="cocodeGuard.canActivate(null, null) | async">
            <a class="nav-link" [routerLink]="['cocodeUpload']" id="cocode-upload-link">Cocode List Manager</a>
          </li>
          <li class="nav-item" routerLinkActive="active" *ngIf="bulkOrderManagerGuard.canActivate(null, null) | async">
            <a class="nav-link" [routerLink]="['bulkOrderManager']" id="bulk-order-manager-link">Bulk Order
              Manager</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['viewOrders']" id="view-orders-link">Orders</a>
          </li>
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['account']" id="account-link">Account</a>
          </li>
        </ul>
        <ul class="navbar-nav"
            *ngIf="!isCurrentUserDoNotCharge && (userRole !== 'ROLE_SPECIAL' && userRole !== 'ROLE_ALL_ACCESS')">
          <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['shoppingCart']" id="shopping-cart" title="View Cart">
              <!-- FIXME:: Convert to new angular fontawesome -->
              <span class="fa fa-shopping-cart"></span>&nbsp;&nbsp;Cart
              ({{ cart && cart.items ? cart.items.length : 0 }})</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>



