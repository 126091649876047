/*!
 * Copyright 2024 National Association of Insurance Commissioners
 */
import { Component, OnInit } from '@angular/core';
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube  } from '@fortawesome/free-brands-svg-icons';
import { faCaretRight, faCircle, faQuestionCircle, faRss } from '@fortawesome/free-solid-svg-icons';
import {ApiAddress, FooterOffice} from '../../model/ncui-interfaces';
import {NcuiAddressService} from '../../service/ncui-address.service';

@Component( {
  selector: 'app-ncui-footer',
  templateUrl: './ncui-footer.component.html',
  styleUrls: [ './ncui-footer.component.scss'],
} )
export class NcuiFooterComponent implements OnInit {
  activeOfficeIndex = 0;
  offices: FooterOffice[];
  faQuestionCircle = faQuestionCircle;
  faCaretRight = faCaretRight;
  faCircle = faCircle;
  faRss = faRss;
  faFacebookF = faFacebookF;
  faTwitter = faTwitter;
  faYoutube = faYoutube;
  faLinkedinIn = faLinkedinIn;

  constructor( private ncuiAddressService: NcuiAddressService ) {}

  ngOnInit() {
    this.ncuiAddressService.getAddresses().subscribe( ( addresses ) => {
      function formatPhone( ph: string ) {
        return `(${ph.slice( 0, 3 )}) ${ph.slice( 3, 6 )}-${ph.slice( 6 )}`;
      }

      this.offices = addresses.map( ( addr: ApiAddress ) => ( {
        name: addr.companyName,
        address: {
          address1: addr.street1,
          address2: addr.street2,
          city: addr.city,
          state: addr.state,
          zip: addr.zip,
        },
        serviceDesk: {
          phone: formatPhone( addr.phone ),
          fax: formatPhone( addr.fax ),
          hoursOfOperation: addr.switchBoardHours,
          daysOfOperation: addr.switchBoardDays,
        },
      } ) );
    } );
  }

  getCopyrightYear(): string {
    return new Date().getFullYear().toString();
  }

  switchActiveOffice( officeIndex: number ): void {
    this.activeOfficeIndex = officeIndex;
  }
}
