/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {HttpClient, HttpEvent, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BillingService} from '../api/billing.service';
import {BillingRemittance} from '../model/billing/billingRemittance';
import {BillingResponse} from '../model/billing/billingResponse';
import {StripeToken} from '../model/billing/stripeToken';

// Declare var dT_;

@Injectable()
export class InsdataBillingService {
  userId: string;

  constructor(
              private readonly billingService: BillingService,
              protected readonly httpClient: HttpClient ) {
    // If (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
    //   DT_.initAngularNg(httpClient, HttpHeaders);
    // }
  }

  public payForCart( body: BillingRemittance, userId: string, observe?: 'body', reportProgress?: boolean ): Observable<BillingResponse>;
  public payForCart( body: BillingRemittance, userId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<BillingResponse>>;
  public payForCart( body: BillingRemittance, userId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<BillingResponse>>;
  public payForCart( body: BillingRemittance, userId: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.billingService.payForCart( body, userId );
  }

  public generatePaymentIntent( userId: string, observe?: 'body', reportProgress?: boolean ): Observable<StripeToken>;
  public generatePaymentIntent( userId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<StripeToken>>;
  public generatePaymentIntent( userId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<StripeToken>>;
  public generatePaymentIntent( userId: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.billingService.generatePaymentIntent( userId );
  }

  public deletePaymentIntent( paymentIntentId: string, userId: string, observe?: 'body', reportProgress?: boolean ): Observable<any>;
  public deletePaymentIntent( paymentIntentId: string, userId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<any>>;
  public deletePaymentIntent( paymentIntentId: string, userId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<any>>;
  public deletePaymentIntent( paymentIntentId: string, userId: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.billingService.deletePaymentIntent( paymentIntentId, userId );
  }

}
