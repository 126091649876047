<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div class="row justify-content-center mb-3">
  <div class="col-xl-8 col-lg-10 col-md-10 col-sm-12">
    <div class="row">
      <div class="col-lg-6 col-xl-5">
        <div class="card checkout-summary">
          <div class="card-header"><h5><strong>Summary of Order</strong></h5></div>
          <div class="card-body">
            <div>Your purchase includes {{ numberOfItems }} <span [ngPlural]="numberOfItems"><ng-template
              ngPluralCase="=1">product</ng-template><ng-template
              ngPluralCase="other">products</ng-template></span>.
            </div>
          </div>
          <div class="card-footer">
            <div><strong>Total Due: {{ totalPrice | currency:'USD':true:'1.2-2' }}</strong></div>
          </div>
        </div>
      </div>

      <div class="col mt-2 mt-lg-0" *ngIf="numberOfItems > 0">
        <form id="payment-form">
          <div class="card">
            <div class="card-header"><h5><strong>Complete Your Order</strong></h5></div>
            <div class="card-body">
              <!-- FIXME:: Change this id to something more meaningful -->
              <div id="form-group">
                <div class="form-control">
                  <p *ngIf="loading">Loading...
                    <!-- FIXME:: Convert to new angular fontawesome -->
                    <span class="fa fa-spinner fa-spin fa-lg"></span></p>
                  <div id="card-element"><!--Stripe.js injects the Card Element--></div>
                </div>
                <br/>
                <p id="card-error" role="alert"></p>
              </div>
            </div>
            <div class="card-footer">
              <div class="btn-toolbar float-end">
                <div class="btn-group">
                  <button type="button" id="submit"
                          class="btn btn-primary btn-sm me-2 form-control" (click)="completeOrder($event)" disabled>
                    <div class="spinner hidden" id="spinner"></div>
                    Complete Order
                  </button>
                </div>
                <div class="btn-group">
                  <button type="button" class="btn btn-sm" (click)="cancelPayment()">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- TODO:: Move to it's own component -->
    <div bsModal #orderConfirmationModal="bs-modal" class="modal" id="orderConfirmationModal" tabindex="-1"
         role="dialog" [config]="{backdrop: 'static'}" (onHide)="continue()">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><strong>Confirmation of Your Order</strong></h5>
          </div>
          <div class="modal-body" *ngIf="orderConfirmationDetail && billingResponse">
            <div class="row d-sm-flex">
              <div class="col"><label>Order Number:</label></div>
              <div class="col">{{ orderConfirmationDetail.orderData.orderId |  slice:-truncatedOrderIdLength }}</div>
              <div class="w-100"></div>
              <div class="col"><label>Transaction Number:</label></div>
              <div class="col">{{ billingResponse.transactionId }}</div>
              <div class="w-100"></div>
              <div class="col"><label>Card Number:</label></div>
              <div class="col">****{{ orderConfirmationDetail.orderData.lastFourCcDigits }}</div>
              <div class="w-100"></div>
              <div class="col"><label>Order Date:</label></div>
              <div class="col">{{ orderConfirmationDetail.orderData.purchaseDate | date:'MM/dd/yyyy' }}</div>
              <div class="w-100"></div>
              <div class="col"><label>Order Total:</label></div>
              <div class="col">{{ orderConfirmationDetail.orderData.totalUsd |  currency:'USD':true:'1.2-2' }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <p class="fw-light">Please note it may take 2-3 days for this charge to be reflected on your
              statement.</p>
            <button type="button" class="btn btn-primary" (click)="continue()" data-bs-dismiss="modal">Continue</button>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO:: Move to it's own component -->
    <div bsModal #submittingPaymentModal="bs-modal" class="modal" id="submittingPaymentModal" tabindex="-1"
         role="dialog" [config]="{backdrop: 'static', keyboard: false}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><strong>Submitting Payment</strong></h5>
          </div>
          <div class="modal-body">
            <!-- FIXME:: Convert to new angular fontawesome -->
            <span class="fa fa-spinner fa-spin fa-2x padded"></span>
            Payment submission is in progress. This could take a moment.
            <div class="alert alert-warning">
              Please do not refresh or leave the page until submission is completed.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TODO:: Use Generic modal component -->
    <div bsModal #submittingPaymentErrorModal="bs-modal" class="modal" id="submittingPaymentErrorModal" tabindex="-1"
         role="dialog" [config]="{backdrop: 'static', keyboard: false}">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"><strong>An Error Occurred</strong></h5>
          </div>
          <div class="modal-body">
            An error occurred when submitting payment. Please check the Orders Page for the status of order
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="continue()" data-bs-dismiss="modal">Continue</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
