<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div class="mt-1 me-3 ms-3">
  <div *ngIf="insdataMessage"
       [ngClass]="getMessageClasses()" role="alert">
    {{ insdataMessage.text }}

    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="clearMessage()">
    </button>
  </div>
</div>
