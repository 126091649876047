/*
 * Copyright 2024 National Association of Insurance Commissioners
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject( OKTA_AUTH ) private oktaAuth: OktaAuth
  ) {
  }

  intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const accessToken = this.oktaAuth.getAccessToken();
    if ( accessToken ) {
      request = request.clone( {
        setHeaders: {
          Authorization: `Bearer ${ accessToken }`,
        },
      } );
    }

    return next.handle( request );
  }
}
