/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable( { providedIn: 'root' } )
export class InsdataLoadingSpinnerService {

  private readonly showLoadingSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );

  showLoadingSpinner(): void {
    this.showLoadingSpinnerSubject.next( true );
  }

  hideLoadingSpinner(): void {
    this.showLoadingSpinnerSubject.next( false );
  }

  getLoadingSpinnerSubject(): BehaviorSubject<boolean> {
    return this.showLoadingSpinnerSubject;
  }

}
