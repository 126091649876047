/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component( {
  selector: 'app-standard-modal',
  templateUrl: './standard-modal.component.html',
  styleUrls: [ './standard-modal.component.scss' ]
} )
export class StandardModalComponent {
  title = 'Standard Modal';
  body = 'This is a standard modal that did not receive any text for the body';
  primaryButtonText = 'Primary';
  primaryButtonFunction: Function;
  secondaryButtonText = 'Secondary';
  secondaryButtonFunction: Function;
  primaryAction = false;
  secondaryAction = false;
  primaryButtonClass = 'btn-primary';
  secondaryButtonClass = 'btn-secondary';


  constructor( public bsModalRef: BsModalRef ) {
  }

  onPrimaryClick(): void {
    if ( this.primaryButtonFunction ) {
      this.primaryButtonFunction();
    }
    this.primaryAction = true;
    this.bsModalRef.hide();
  }

  onSecondaryClick(): void {
    if ( this.secondaryButtonFunction ) {
      this.secondaryButtonFunction();
    }
    this.secondaryAction = true;
    this.bsModalRef.hide();
  }

}
