/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component, OnInit } from '@angular/core';
// FIXME:: Change to import only what is needed from lodash
import * as _ from 'lodash';
import { User } from '../../model/customer/user';
import { InsdataCustomerService } from '../../service/insdata-customer.service';
import { InsdataMessageService } from '../../service/insdata-message.service';

interface AccountFormField {
  text: string;
  minLength: number;
  disabled: boolean;
}

interface AccountUpdateForm {
  firstName: AccountFormField;
  lastName: AccountFormField;
  companyName: AccountFormField;
  email: AccountFormField;
  phone: AccountFormField;
  username: AccountFormField;
}

@Component( {
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: [ './account.component.scss' ],
} )
export class AccountComponent implements OnInit {

  private static setToNullIfEmptyString( accountFormField: AccountFormField ) {
    if ( accountFormField.text != null && accountFormField.text.trim().length === 0 ) {
      accountFormField.text = null;
    }
  }

  loading = false;
  _: any = _;
  defaultMinLength = 3;
  emptyFormField: AccountFormField = { text: '', minLength: this.defaultMinLength, disabled: true };
  blankAccountForm: AccountUpdateForm = {
    firstName: { ...this.emptyFormField },
    lastName: { ...this.emptyFormField },
    companyName: { ...this.emptyFormField, disabled: false }, // CompanyName is the only field that is editable
    email: { ...this.emptyFormField },
    phone: { ...this.emptyFormField },
    username: { ...this.emptyFormField },
  };
  accountForm: AccountUpdateForm;
  currentUser: User;

  constructor(
    private readonly customerService: InsdataCustomerService,
    private readonly insdataMessageService: InsdataMessageService
  ) {
  }

  ngOnInit() {
    this.loading = true;
    this.customerService.getCurrentUser().subscribe( ( user: User ) => {
      this.loading = false;
      this.currentUser = user;
      this.resetForm();
    }, () => {
      this.loading = false;
      this.insdataMessageService.showErrorMessage( 'An Error Occurred when trying to load your profile.' );
    } );
  }

  setAccountUserForm( user: User ) {
    this.accountForm.firstName.text = user.firstName;
    this.accountForm.companyName.text = user.companyName;
    this.accountForm.email.text = user.email;
    this.accountForm.lastName.text = user.lastName;
    this.accountForm.phone.text = user.phone;
    this.accountForm.username.text = user.userName;
  }

  resetForm(): void {
    this.accountForm = _.cloneDeep( this.blankAccountForm );
    this.setAccountUserForm( this.currentUser );
    this.insdataMessageService.clearMessage();
  }

  updateAccount(): void {
    this.loading = true;
    AccountComponent.setToNullIfEmptyString( this.accountForm.companyName );
    this.customerService.updateUser( {
      userId: this.currentUser.userId,
      billingFlag: this.currentUser.billingFlag,
      userStatus: this.currentUser.userStatus,
      companyName: this.accountForm.companyName.text,
    } ).subscribe( ( result ) => {
      if ( !result ) {
        this.loading = false;
        this.insdataMessageService.showErrorMessage( 'User Information could not be updated' );
      } else {
        this.customerService.getCurrentUser().subscribe( ( user: User ) => {
          this.loading = false;
          this.currentUser = user;
          this.resetForm();
          this.insdataMessageService.showInformationalMessage( 'User information has been updated' );
        } );
      }
    }, ( err ) => {
      this.loading = false;
      this.insdataMessageService.showErrorMessage( 'An Error Occurred when trying to save User Information' );
      console.error( err );
    } );
  }

  displayField( field: string ): string {
    return field === 'phone' ? 'Phone Number' : _.startCase( field );
  }

  getPlaceholder( field: string ): string {
    return field === 'companyName' ? 'Company Name (Optional)' : _.startCase( field );
  }

  formIsValid(): boolean {
    return true;
  }
}
