/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { find, orderBy, result, uniq } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { Subscription } from 'rxjs';
import { Cart } from '../../model/cart/cart';
import { ProductDataKey } from '../../model/common/productDataKey';
import { DownloadLinkCriteria } from '../../model/company/downloadLinkCriteria';
import { User } from '../../model/customer/user';
import { EntityStatus } from '../../model/order/entityStatus';
import { OrderBaseData } from '../../model/order/orderBaseData';
import { OrderDetail } from '../../model/order/orderDetail';
import { OrderItemDetail } from '../../model/order/orderItemDetail';
import { OrdersSearchResult } from '../../model/order/ordersSearchResult';
import { InsdataCompanySearchService } from '../../service/insdata-company-search.service';
import { InsdataCustomerService } from '../../service/insdata-customer.service';
import { InsdataLoadingSpinnerService } from '../../service/insdata-loading-spinner.service';
import { InsdataMessageService } from '../../service/insdata-message.service';
import { InsdataOrdersService } from '../../service/insdata-orders.service';
import { InsdataShoppingCartService } from '../../service/insdata-shopping-cart.service';
import { InsdataDateConverter } from '../../utils/insdata-date-converter';

interface CodeDisplayObj {
  code: string;
  display: string;
}

@Component( {
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: [ './view-orders.component.scss' ],
} )
export class ViewOrdersComponent implements OnInit, OnDestroy {
  @ViewChild( 'viewDetailsModal', { static: false } ) viewDetailsModal: ModalDirective;
  @ViewChild( 'loadingOrderDetailModal', { static: false } ) loadingOrderDetailModal: ModalDirective;
  @ViewChild( 'loadingDownloadPage', { static: false } ) loadingDownloadPage: ModalDirective;
  @ViewChild( 'errorOccurredModal', { static: false } ) errorOccurredModal: ModalDirective;

  cart: Cart;
  orders: OrderBaseData[] = [];
  truncatedUuidLength = 6;
  showTab = 'BULK';
  orderHeaders: CodeDisplayObj[] = [
    { code: 'orderId', display: 'Order #' },
    { code: 'purchaseDate', display: 'Order Date' },
    { code: 'transactionId', display: 'Transaction #' },
    { code: 'orderStatus', display: 'Status' },
    { code: 'totalUsd', display: 'Order Total' },
    { code: 'viewInvoice', display: 'Invoice' },
  ];
  bulkOrderHeaders: CodeDisplayObj[] = [
    { code: 'orderId', display: 'Order Number' },
    { code: 'purchaseDate', display: 'Order Creation Date' },
    { code: 'orderStatus', display: 'Status' },
    { code: 'orderDetails', display: 'Order Details' },
  ];
  orderStatuses: CodeDisplayObj[] = [
    { code: EntityStatus.Active, display: 'Download Bulk Orders PDF' },
    { code: EntityStatus.Failed, display: 'Transaction Failed' },
    { code: EntityStatus.NoChanges, display: 'No Changes Available' },
    { code: EntityStatus.Pending, display: 'In Process' },
    { code: EntityStatus.Expired, display: 'Expired' },
    { code: EntityStatus.Inactive, display: 'Inactive' },
    { code: EntityStatus.Cancelled, display: 'Cancelled' },
  ];
  sortOrder: 'asc' | 'desc' = 'desc';
  sortFields: string[] = [ 'purchaseDate', 'orderId' ];
  activeOrderDetail: OrderDetail; // Order that the user clicks to view the details of
  activeOrderItemDetails: Array<OrderItemDetail>;
  convertSecondsToMilliseconds = 1000;
  entityStatus = EntityStatus; // For Component Context
  helpText = 'Files within a purchase are subject to change at any time. See HELP for more details.';
  userRole: string;
  resultsHavePagination = true;
  rowsPerPage = 10;
  currentPage = 1;
  maxSize = 5;
  totalResult: number;
  ordersSearchResults: OrdersSearchResult;
  user: User;
  selectedBulkOrderLinks: Array<string>;
  selectedBulkOrderId: string;
  shouldShowDownloadPage = false;
  visitedIndexes: Array<number> = [];
  subscriptions: Subscription[] = [];
  readonly downloadPageMessage = 'Please, Initiate the downloads within 10 minutes. If the download links do not work, please Login again.';

  constructor(
    private readonly shoppingCartService: InsdataShoppingCartService,
    private readonly insdataOrderService: InsdataOrdersService,
    private readonly insdataDateConverter: InsdataDateConverter,
    private readonly insdataMessageService: InsdataMessageService,
    private readonly insdataCompanyService: InsdataCompanySearchService,
    private readonly insdataCustomerService: InsdataCustomerService,
    private readonly router: Router,
    private readonly insdataSpinner: InsdataLoadingSpinnerService
  ) {
  }

  ngOnInit(): void {
    this.cart = this.shoppingCartService.cart;

    this.getUserInfo();

    this.subscriptions.push(
      this.insdataCustomerService.getCurrentUserRole().subscribe( {
        next: ( data: string ) => {
          this.userRole = data;
          if ( this.userRole === 'ROLE_SPECIAL' || this.userRole === 'ROLE_ALL_ACCESS' ) {
            this.fetchAndDisplayBulkOrders();
          } else {
            this.fetchAndDisplayCartOrders();
          }
        },
        error: () => {
          this.insdataMessageService.showErrorMessage( 'Error obtaining user role.' );
        }
      } )
    );
  }

  getUserInfo() {
    this.subscriptions.push(
      this.insdataCustomerService.getCurrentUser().subscribe(
        {
          next: ( data ) => {
            this.user = data;
          },
          error: () => {
            this.insdataMessageService.showErrorMessage( 'Error obtaining user information.' );
          }
        } )
    );
  }

  fetchOrders( orderType: any, start?: number, limit?: number ) {
    this.insdataSpinner.showLoadingSpinner();

    this.subscriptions.push(
      this.insdataOrderService.fetchOrders( orderType, start, limit ).subscribe( {
        next: ( searchResult: OrdersSearchResult ) => {
          if ( searchResult?.orders ) {
            this.ordersSearchResults = searchResult;
            this.totalResult = this.ordersSearchResults.pageSearchResults.numItemsMatched;
            this.orders = searchResult.orders.map( ( order: any ) => {
              return {
                ...order,
                purchaseDate: this.insdataDateConverter.convert( order.purchaseDate ),
              };
            } );
          }
          this.insdataSpinner.hideLoadingSpinner();
        },
        error: () => {
          this.insdataMessageService.showErrorMessage( 'An error occurred when trying to load orders' );
          this.insdataSpinner.hideLoadingSpinner();
        },
        complete: () => {
          this.insdataSpinner.hideLoadingSpinner();
        }
      } )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach( ( subscription: Subscription ) => subscription.unsubscribe() );
  }

  getCurrentRows(): OrderBaseData[] {
    return orderBy( this.orders, this.sortFields.map( ( field: string ) => field ), this.sortOrder );
  }

  sort( desiredSortField: string ): void {
    if ( this.sortFields[ 0 ] === desiredSortField ) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      return;
    }
    this.sortFields = uniq( [ desiredSortField ].concat( this.sortFields ) );
  }

  downloadOrderFiles( orderId: string ): void {
    this.subscriptions.push(
      this.insdataCompanyService.downloadOrderFiles( this.user.userId, orderId ).subscribe( {
        next: ( link: string ) => this.downloadIndividualOrder( link ),
        error: () => this.insdataMessageService.showErrorMessage( 'An error occurred when downloading your file.' )
      } )
    );

  }

  downloadZip( orderId: string ) {
    this.insdataMessageService.showWarningMessage( 'Downloading your zip...' );
    const orderFileCriteria: DownloadLinkCriteria = {
      userId: this.user.userId, orderId: orderId, downloadFileType: 'ORDER_FILE',
    };
    this.subscriptions.push(
      this.insdataOrderService.fetchOrderDetail( orderId ).subscribe( {
        next: ( data: OrderDetail ) => {
          if ( data.orderData.orderType === 'BULK' ) {
            this.showDownloadPage( orderFileCriteria );
          } else if ( data.orderData.orderType === 'CART' ) {
            this.downloadOrderFiles( orderId );
          }
        },
        error: () => console.error( 'Something went wrong fetching order details using \'/orders/{userId}/{orderId}\' api call.' )
      } )
    );
  }

  downloadIndividualOrder( link: string ): void {
    const anchor = document.createElement( 'a' );
    anchor.href = link;
    anchor.click();
    this.insdataMessageService.clearMessage();
  }

  viewOrderDetails( order: OrderBaseData ) {
    this.insdataMessageService.clearMessage();
    this.loadingOrderDetailModal.show();

    this.subscriptions.push(
      this.insdataOrderService.fetchOrderDetail( order.orderId ).subscribe( {
        next: ( orderDetail: OrderDetail ) => {
          this.activeOrderDetail = orderDetail;
          this.activeOrderDetail.orderData.purchaseDate = this.insdataDateConverter.convert( this.activeOrderDetail.orderData.purchaseDate );
          this.activeOrderItemDetails = orderDetail.orderItemDetails;
          this.loadingOrderDetailModal.hide();
          this.viewDetailsModal.show();
        },
        error: () => {
          this.loadingOrderDetailModal.hide();
          this.errorOccurredModal.show();
        }
      } )
    );
  }

  getFormattedStatementFilingPeriod( productDataKey: ProductDataKey ): string {
    const statementPages = ' Statement Pages';
    if ( this.isQuarterly( productDataKey.submissionFilingPeriod ) ) {
      return productDataKey.submissionFilingPeriod + statementPages;
    }
    if ( productDataKey.isKey ) {
      return `Key ${ productDataKey.submissionFilingPeriod }${ statementPages }`;
    } else {
      return `Non-Key ${ productDataKey.submissionFilingPeriod }${ statementPages }`;
    }
  }

  isQuarterly( filingPeriod: string ): boolean {
    return filingPeriod && filingPeriod.indexOf( 'Quarter' ) >= 0;
  }

  getOrderStatusLabel( status: EntityStatus ): string {
    const label = result<string>( find( this.orderStatuses, function ( obj ) {
      return obj.code === status;
    } ), 'display' );
    if ( !label ) {
      return 'Transaction Failed';
    }
    return label;
  }

  fetchAndDisplayBulkOrders() {
    this.showTab = 'BULK';
    this.resetOrders();
    const start = ( this.currentPage - 1 ) * this.rowsPerPage;
    this.fetchOrders( 'BULK', start, this.rowsPerPage );
  }

  fetchAndDisplayCartOrders() {
    this.showTab = 'CART';
    this.resetOrders();
    this.fetchOrders( 'CART', ( this.currentPage - 1 ) * this.rowsPerPage, this.rowsPerPage );
  }

  calcTotalPages() {
    if ( !this.ordersSearchResults.pageSearchResults.numItemsMatched ) {
      console.error( 'no orders available!' );  //  S
    }
    return Math.ceil( this.ordersSearchResults.pageSearchResults.numItemsMatched / this.rowsPerPage );
  }

  navigateFromPagination( event: PageChangedEvent ): void {
    // The following code is here to prevent a loop that was going on with pagination
    if ( this.currentPage === event.page ) {
      return;
    }
    if ( this.showTab === 'BULK' ) {
      console.warn( 'navigate fetchAndDisplayBulkOrders' );
      this.fetchAndDisplayBulkOrders();
    } else if ( this.showTab === 'CART' ) {
      this.fetchAndDisplayCartOrders();
    }
  }

  calcStartNumber(): number {
    return ( this.rowsPerPage * ( this.currentPage - 1 ) ) + 1;
  }

  calcEndNumber(): number {
    return this.currentPage === this.calcTotalPages() ? this.ordersSearchResults.pageSearchResults.numItemsMatched : this.calcStartNumber() + ( this.rowsPerPage - 1 );
  }

  resetOrders() {
    this.orders = [];
  }

  downloadFileUsingLink( value: DownloadLinkCriteria ) {
    this.subscriptions.push(
      this.insdataCompanyService.downloadOrderLink( value ).subscribe( {
        next: ( links: string[] ) => {
          const anchor = document.createElement( 'a' );
          anchor.href = links[ 0 ];
          anchor.click();
          this.insdataMessageService.clearMessage();
        },
        error: () => this.insdataMessageService.showErrorMessage( 'An error occurred when trying to download your file.', false, 10000 )
      } )
    );
  }

  downloadManifestFile( row: OrderBaseData ) {
    this.insdataMessageService.showWarningMessage( 'Downloading manifest file...' );
    const manifestFileCriteria: DownloadLinkCriteria = {
      userId: this.user.userId, downloadFileType: 'MANIFEST_FILE', orderId: row.orderId,
    };
    this.downloadFileUsingLink( manifestFileCriteria );
  }

  goToDownloadPage( orderId: string ) {
    this.loadingDownloadPage.show();
    this.selectedBulkOrderId = orderId;
    const orderFileCriteria: DownloadLinkCriteria = {
      userId: this.user.userId, orderId: orderId, downloadFileType: 'ORDER_FILE',
    };
    this.showDownloadPage( orderFileCriteria );
  }

  showDownloadPage( orderFileCriteria: DownloadLinkCriteria ) {
    this.subscriptions.push(
      this.insdataCompanyService.downloadOrderLink( orderFileCriteria ).subscribe( {
        next: ( links: string[] ) => {
          this.selectedBulkOrderLinks = [ ...links ].sort( ( a, b ) => {
            const filename1 = this.getFileName( a );
            const filename2 = this.getFileName( b );

            const number1 = filename1.substring( filename1.indexOf( '_' ) + 1, filename1.indexOf( '.' ) - 1 );
            const number2 = filename2.substring( filename2.indexOf( '_' ) + 1, filename2.indexOf( '.' ) - 1 );

            return +number1 - +number2;
          } );
          this.shouldShowDownloadPage = true;
          this.loadingDownloadPage.hide();
          this.insdataMessageService.clearMessage();
        },
        error: () => {
          this.loadingDownloadPage.hide();
          this.insdataMessageService.showErrorMessage( 'An error occurred when loading the download page.', false, 10000 );
        }
      } )
    );
  }

  goBackToMainOrdersPage() {
    this.selectedBulkOrderId = null;
    this.selectedBulkOrderLinks = null;
    this.shouldShowDownloadPage = false;
    this.router.navigate( [ 'home/viewOrders' ] ).then(
      // Do nothing
    );
  }

  getFileName( link: string ): string {
    const orderId = this.selectedBulkOrderId.slice( -this.truncatedUuidLength );
    return link.substring( link.indexOf( orderId ), link.indexOf( 'zip' ) + 3 );
  }

  downloadBulkOrder( index: number ) {
    if ( ( this.visitedIndexes.indexOf( index ) <= -1 ) ) {
      this.visitedIndexes.push( index );
    }
    const anchor = document.createElement( 'a' );
    anchor.href = this.selectedBulkOrderLinks[ index ];
    anchor.click();
  }

  hasBeenVisited( index: number ): boolean {
    return this.visitedIndexes.indexOf( index ) > -1;
  }
}

