<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<footer id="GBL_Common_Footer">
  <div class="footer-app">
    <div id="GBL_Navigation_Quicklinks" class="quickLinks clearfix hidden hidden-print">
      <div class="col col-sm-3 helpDesk">
        <h2 class="helpDeskTitle">NAIC Help Desk</h2>
        <a class="helpDeskPhone" href="tel:8167838990" id="cui-help-desk-phone">(816) 783-8990</a>
        <a class="helpDeskSubtitle" href="http://www.naic.org/index_help.htm" target="_blank" id="cui-help-desk-info">Help
          Desk Info</a>
      </div><!-- /.col -->
      <div class="col col-sm-3 quicklink-col-1">
      </div><!-- /.col -->
      <div class="col col-sm-3 quicklink-col-2">
      </div><!-- /.col -->
      <div class="col col-sm-3 quicklink-col-3">
      </div><!-- /.col -->
    </div><!-- /.quickLinks -->
    <div id="GBL_User_Feedback" class="footer-feedback hidden hidden-print">
      <div class="feedback clearfix">
        <div class="col-sm-6 col-md-4 feedback-input-label">
          <h1 class="feedback-copy">Send Feedback</h1>
          <div class="feedback-contents">
            <p>The NAIC greatly values your feedback.</p>
            <p>If you have thoughts on how to improve this site, please fill out this form.</p>
            <p>Thank you for your time.</p>
          </div>
        </div>
        <!-- /.col -->
        <div class="col-sm-6 col-md-8 feedback-inputWrap">
          <form id="frmFeedback" action="#">
            <input name="username" type="hidden">
            <input name="realm" type="hidden">
            <input name="appTitle" type="hidden">
            <input name="appBox" type="hidden">

            <label class="sr-only label-control" for="feedback">Feedback User Input</label>
            <textarea name="feedback" id="feedback" class="form-control feedback-userInput"
                      placeholder="Please enter your feedback"></textarea>

            <button type="submit" class="btn btn-primary btn-lg btn-block ui-element" id="cui-feedback-submit">Send
              Feedback
            </button>
          </form>
        </div>
        <!-- ./col -->
      </div>
      <!-- /.feedback -->
    </div>
    <!-- /.footer-feedback -->
  </div>

  <div id="GBL_Footer" class="container-fluid footer-global hidden-print">
    <div class="footer-contact row">
      <div class="col">
        <!-- <img class="footer-logo-naic" width="100" height="59" src="/public/common-ui/enterprise/2.0.1/img/naicLogo-footer.png" alt="NAIC Logo"> -->
        <div class="office-list">
          <div *ngFor="let office of offices; let i = index" class="office" [class.active]="activeOfficeIndex === i">
            <div class="office__header" (click)="switchActiveOffice(i)">
              <span class="office__name">{{ office.name }}</span>
              <fa-icon [icon]="faQuestionCircle"></fa-icon>
              <fa-icon [icon]="faCaretRight"></fa-icon>
            </div>
            <div class="office__details">
              <div class="office__address">
                <div><b>Address</b></div>
                <div class="address-1">{{ office.address.address1 }}</div>
                <div class="address-2">{{ office.address.address2 }}</div>
                <div class="address-3">
                  <span class="city">{{ office.address.city }}</span>,
                  <span class="state">{{ office.address.state }}</span>
                  <span class="zip">{{ office.address.zip }}</span>
                </div>
              </div>
              <div class="office__contact">
                <div><b>Service Desk</b></div>
                <div class="office__phone"><span class="value">{{ office.serviceDesk.phone }}</span></div>
                <div class="office__fax hidden"><b>Fax:</b> <span class="value">{{ office.serviceDesk.fax }}</span>
                </div>
                <div class="office__hours-of-operation"><span
                  class="value">{{ office.serviceDesk.hoursOfOperation }}</span></div>
                <div class="office__days-of-operation"><span
                  class="value">{{ office.serviceDesk.daysOfOperation }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div id="GBL_Footer_SocialMedia_Icons" class="socialBlock col text-center">
        <a class="rssUrl" href="http://www.naic.org/press.xml" target="_blank" id="cui-link-rss" title="NAIC RSS">
          <fa-layers [fixedWidth]="true">
            <fa-icon [icon]="faCircle" [inverse]="true"></fa-icon>
            <fa-icon [icon]="faRss" transform="shrink-8" class="social-logo faRss"></fa-icon>
          </fa-layers>
          <span class="sr-only">Press</span>
        </a>
        <a class="facebookUrl" href="http://www.facebook.com/NAIC.News" target="_blank" id="cui-link-facebook"
           title="NAIC Facebook">
          <fa-layers [fixedWidth]="true">
            <fa-icon [icon]="faCircle" [inverse]="true"></fa-icon>
            <fa-icon [icon]="faFacebookF" transform="shrink-8" class="social-logo faFacebookF"></fa-icon>
          </fa-layers>
          <span class="sr-only">Facebook</span>
        </a>
        <a class="twitterUrl" href="http://twitter.com/NAIC_News" target="_blank" id="cui-link-twitter"
           title="NAIC Twitter">
          <fa-layers [fixedWidth]="true">
            <fa-icon [icon]="faCircle" [inverse]="true"></fa-icon>
            <fa-icon [icon]="faTwitter" transform="shrink-8" class="social-logo faTwitter"></fa-icon>
          </fa-layers>
          <span class="sr-only">Twitter</span>
        </a>
        <a class="youtubeUrl" href="https://www.youtube.com/user/NAICCommunications" target="_blank"
           id="cui-link-youtube" title="NAIC YouTube">
          <fa-layers [fixedWidth]="true">
            <fa-icon [icon]="faCircle" [inverse]="true"></fa-icon>
            <fa-icon [icon]="faYoutube" transform="shrink-8" class="social-logo faYoutube"></fa-icon>
          </fa-layers>
          <span class="sr-only">YouTube</span>
        </a>
        <a class="linkedinUrl" href="http://linkedin.com/company/naic" target="_blank" id="cui-link-linked-in"
           title="NAIC LinkedIn">
          <fa-layers [fixedWidth]="true">
            <fa-icon [icon]="faCircle" [inverse]="true"></fa-icon>
            <fa-icon [icon]="faLinkedinIn" transform="shrink-8" class="social-logo faLinkedinIn"></fa-icon>
          </fa-layers>
          <span class="sr-only">LinkedIn</span>
        </a>
      </div><!-- /.socialBlock -->
    </div>
    <div id="GBL_Footer_Privacy" class="footer-privacy row">
      <div class="col text-center small"><a href="http://www.naic.org/privacy_statement.htm">Privacy Policy</a></div>
    </div><!-- /.footer-copyright -->
    <div id="GBL_Footer_Copyright" class="footer-copyright row">
      <div class="col">&copy; {{ getCopyrightYear() }} National Association of Insurance Commissioners. All rights
        reserved.
      </div>
    </div><!-- /.footer-copyright -->
  </div><!-- /.footer-global -->
</footer>
