/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { InsdataCompanySearchService } from '../../service/insdata-company-search.service';
import { SharedModule } from '../../shared/shared.module';
import { CompanyRoutingModule } from './company-routing.module';
import { CompanySearchDetailComponent } from './company-search-detail/company-search-detail.component';
import { CompanySearchResultHostDirective } from './company-search-result-host.directive';
import { CompanySearchResultsComponent } from './company-search-results/company-search-results.component';
import { CompanySearchComponent } from './company-search/company-search.component';

@NgModule( {
  imports: [
    CompanyRoutingModule,
    HttpClientModule,
    SharedModule,
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    FontAwesomeModule
  ],
  declarations: [
    CompanySearchComponent,
    CompanySearchDetailComponent,
    CompanySearchResultsComponent,
    CompanySearchResultHostDirective,
  ],
  entryComponents: [
    CompanySearchResultsComponent,
    CompanySearchDetailComponent,
  ],
  providers: [
    InsdataCompanySearchService,
  ],
} )
export class CompanyModule {
}
