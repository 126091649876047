/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { from as observableFrom, Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map, mergeMap } from 'rxjs/operators';
import { CartService } from '../api/cart.service';
import { Cart } from '../model/cart/cart';
import { CartItem } from '../model/cart/cartItem';
import { ProductData } from '../model/common/productData';
import { ProductDataInput } from '../model/common/productDataInput';
import { UserDetail } from '../model/customer/userDetail';
import { InsdataCustomerService } from './insdata-customer.service';
import { OktaAuthService } from './okta-auth.service';

// Declare var dT_;

@Injectable()
export class InsdataShoppingCartService {
  cartChange: Subject<Cart> = new Subject<Cart>();
  cart: Cart;
  private userId: any;

  constructor(
    private readonly cartApi: CartService,
    private readonly oktaAuthService: OktaAuthService,
    private readonly insdataCustomerService: InsdataCustomerService,
    protected readonly httpClient: HttpClient
  ) {
    // If (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
    //   DT_.initAngularNg(httpClient, HttpHeaders);
    // }
  }

  addToCart(productDataInput: ProductDataInput): Observable<Cart> {
    return observableFrom(this.getUserId()).pipe(
      mergeMap((userId) => {
        return this.cartApi.postProduct(userId, productDataInput).pipe(
          map((response) => {
            this.cart = response;
            this.cartChange.next(this.cart);
            return response;
          })
        );
      })
    );
  }

  clearCart(): Observable<Cart> {
    return observableFrom(this.getUserId()).pipe(
      mergeMap((userId) => {
        return this.cartApi.emptyCartForUser(userId).pipe(
          map((response) => {
            this.cart = response;
            this.cartChange.next(this.cart);
            return response;
          })
        );
      })
    );
  }

  refreshCart(): Observable<Cart> {
    return observableFrom(this.getUserId()).pipe(
      mergeMap((userId) => {
        return this.cartApi.fetchCartForUser(userId).pipe(
          map((response) => {
            this.cart = response;
            this.cartChange.next(this.cart);
            return response;
          })
        );
      })
    );
  }

  removeProductFromCart(product: ProductData): Observable<Cart> {
    return observableFrom(this.getUserId()).pipe(
      mergeMap((userId) => {
        return this.cartApi
          .removeItemFromCart(userId, this.getCartIdForProduct(product))
          .pipe(
            map((response) => {
              this.cart = response;
              this.cartChange.next(this.cart);
              return response;
            })
          );
      })
    );
  }

  removeCartItemFromCart(cartItem: CartItem): Observable<Cart> {
    return observableFrom(this.getUserId()).pipe(
      mergeMap((userId) => {
        return this.cartApi
          .removeItemFromCart(userId, cartItem.cartItemId)
          .pipe(
            map((response) => {
              this.cart = response;
              this.cartChange.next(this.cart);
              return response;
            })
          );
      })
    );
  }

  hasProductInCart(productData: ProductData): boolean {
    if (this.cart) {
      return (
        _.find(this.cart.items, function (item) {
          return (
            item.product.productDataKey.isKey ===
              productData.productDataKey.isKey &&
            item.product.productDataKey.submissionFilingPeriod ===
              productData.productDataKey.submissionFilingPeriod &&
            item.product.productDataKey.statementYear ===
              productData.productDataKey.statementYear &&
            item.product.productDataKey.companyCoCode ===
              productData.productDataKey.companyCoCode
          );
        }) !== undefined
      );
    } else {
      return false;
    }
  }

  getCartIdForProduct(productData: ProductData): any {
    const cartItem: CartItem = _.find(this.cart.items, function (item) {
      return (
        item.product.productDataKey.isKey ===
          productData.productDataKey.isKey &&
        item.product.productDataKey.submissionFilingPeriod ===
          productData.productDataKey.submissionFilingPeriod &&
        item.product.productDataKey.statementYear ===
          productData.productDataKey.statementYear &&
        item.product.productDataKey.companyCoCode ===
          productData.productDataKey.companyCoCode
      );
    });
    return cartItem.cartItemId;
  }

  // TODO:: Move this to the customer service
  private async getUserId(): Promise<any> {
    if (!this.userId) {
      if (!this.oktaAuthService.userInfo) {
        await this.oktaAuthService.fetchUserInfoAsync();
      }
      const userDetail: UserDetail = await this.insdataCustomerService
        .fetchUserByName(this.oktaAuthService.userInfo.preferred_username)
        .toPromise();
      this.userId = userDetail.user.userId;
    }
    return this.userId;
  }
}
