/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { lastValueFrom } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { InsdataCustomerService } from '../service/insdata-customer.service';
import { InsdataMessageService } from '../service/insdata-message.service';
import { OktaAuthService } from '../service/okta-auth.service';

@Injectable()
export class UserGuard implements CanActivateChild {

  constructor(
    private readonly oktaAuthService: OktaAuthService,
    private readonly insdataCustomerService: InsdataCustomerService,
    private readonly insdataMessageService: InsdataMessageService
  ) {
  }

  async canActivateChild( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise<boolean> {
    const authenticated = await this.oktaAuthService.isLoggedIn();

    if ( authenticated ) {
      return lastValueFrom( this.insdataCustomerService.isCurrentUserAllowedForIndustry().pipe( map( ( result ) => {
        if ( !result ) {
          this.insdataMessageService.showErrorMessage( 'You are not authorized to access this site' );
        }
        return result;
      } ) ) ).then( ( lastData ) => lastData );
    } else {
      return false;
    }
  }
}
