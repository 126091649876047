/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CompaniesStatementsOrderBy } from '../../../model/company/companiesStatementsOrderBy';
import { CompaniesStatementsSearchCriteria } from '../../../model/company/companiesStatementsSearchCriteria';
import { CompaniesStatementsSearchResults } from '../../../model/company/companiesStatementsSearchResults';
import { CompanyData } from '../../../model/company/companyData';
import { PageSearchCriteria } from '../../../model/company/pageSearchCriteria';
import { StatementTypes } from '../../../model/company/statementTypes';
import { States } from '../../../model/company/states';
import { TextSearchFlag } from '../../../model/company/textSearchFlag';
import { InsdataCompanySearchService } from '../../../service/insdata-company-search.service';
import { InsdataCustomerService } from '../../../service/insdata-customer.service';
import { InsdataLoadingSpinnerService } from '../../../service/insdata-loading-spinner.service';
import { InsdataMessageService } from '../../../service/insdata-message.service';

@Component( {
  selector: 'app-company-search',
  templateUrl: './company-search.component.html',
  styleUrls: [ './company-search.component.scss' ],
} )
export class CompanySearchComponent implements OnInit, OnDestroy {
  companySearchCriteria: CompaniesStatementsSearchCriteria;
  companiesStatementsResults: CompaniesStatementsSearchResults;
  selectedCompany: CompanyData;
  hasSearched: boolean;
  allStatementTypes: StatementTypes;
  allStates: States;
  isUserStandard: Observable<boolean>;
  subscriptions: Subscription[] = [];
  private readonly defaultCompanySearchCriteria: CompaniesStatementsSearchCriteria = {
    textSearchFlag: TextSearchFlag.Contains,
    pageSearchCriteria: {
      maxItems: 10,
      startItem: 0,
    } as PageSearchCriteria,
    orderBy: CompaniesStatementsOrderBy.CompanyNameAsc,
  };

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly companySearchService: InsdataCompanySearchService,
    private readonly customerService: InsdataCustomerService,
    private readonly loadingSpinnerService: InsdataLoadingSpinnerService,
    private readonly insdataMessageService: InsdataMessageService
  ) {
  }

  ngOnInit() {
    this.resetCompanySearch();
    this.setDefaultSearch();
    this.isUserStandard = this.customerService.isCurrentUserStandard();
    if ( sessionStorage.getItem( 'companies' ) != null ) {
      this.companiesStatementsResults = JSON.parse( sessionStorage.getItem( 'companies' ) );
    }
    if ( sessionStorage.getItem( 'companySearchCriteria' ) != null ) {
      this.companySearchCriteria = JSON.parse( sessionStorage.getItem( 'companySearchCriteria' ) );
    }

    this.activatedRoute.data.subscribe( ( data: { statementTypes: StatementTypes, states: States } ) => {
      if ( data ) {
        this.allStatementTypes = data.statementTypes;
        this.allStates = data.states;
      }
    } );

    const element = document.querySelector( '#GBL_Common_Header' );
    element.scrollIntoView();
  }

  searchCompanies() {
    this.companySearchCriteria.companyName = this.companySearchCriteria.companyName ? this.companySearchCriteria.companyName.trim() : null;
    this.companySearchCriteria.pageSearchCriteria.startItem = 0;
    this.searchCompany( this.companySearchCriteria );
  }

  resetForm(): void {
    this.resetCompanySearch();
    this.hasSearched = false;
  }

  onCloseDetail(): void {
    this.selectedCompany = undefined;
    this.searchCompany( this.companySearchCriteria );
  }

  onNewPage( newPage: number ): void {
    this.companySearchCriteria.pageSearchCriteria.startItem = ( newPage - 1 ) * this.companySearchCriteria.pageSearchCriteria.maxItems;
    this.searchCompany( this.companySearchCriteria );
  }

  onNewSort( newSort: CompaniesStatementsOrderBy ): void {
    this.companySearchCriteria.orderBy = newSort;
    this.searchCompany( this.companySearchCriteria );
  }

  onSelectedCompany( selectedCompany: CompanyData ): void {
    this.selectedCompany = selectedCompany;
  }

  ngOnDestroy() {
    this.subscriptions.forEach( ( subscription: Subscription ) => {
      subscription.unsubscribe();
    } );
  }

  resetCompanySearch() {
    this.companySearchCriteria = { ...this.defaultCompanySearchCriteria };
    this.companySearchCriteria.pageSearchCriteria.startItem = 0;
    this.companiesStatementsResults = undefined;
    this.selectedCompany = undefined;
    sessionStorage.removeItem( 'companySearchCriteria' );
    sessionStorage.removeItem( 'companies' );
    this.loadingSpinnerService.hideLoadingSpinner();
    this.setDefaultSearch();
  }

  setDefaultSearch() {
    this.companySearchCriteria.statementType = undefined;
    this.companySearchCriteria.stateOfDomicile = undefined;
    this.companySearchCriteria.licensedInState = undefined;
  }

  searchCompany( companySearchCriteria: CompaniesStatementsSearchCriteria ) {
    this.loadingSpinnerService.showLoadingSpinner();
    this.subscriptions.push(
      this.companySearchService.searchNaicCompaniesStatements( companySearchCriteria ).subscribe( {
        next: ( result: CompaniesStatementsSearchResults ) => {
          this.loadingSpinnerService.hideLoadingSpinner();
          this.companiesStatementsResults = result;
          sessionStorage.removeItem( 'companies' );
          sessionStorage.setItem( 'companies', JSON.stringify( this.companiesStatementsResults ) );
          sessionStorage.removeItem( 'companySearchCriteria' );
          sessionStorage.setItem( 'companySearchCriteria', JSON.stringify( this.companySearchCriteria ) );
        },
        error: () => {
          this.loadingSpinnerService.hideLoadingSpinner();
          this.insdataMessageService.showErrorMessage( 'An error occurred when searching for companies' );
          this.companiesStatementsResults = undefined;
        },
        complete: () => {
          this.loadingSpinnerService.hideLoadingSpinner();
        }
      } )
    );
  }

  onlyNumbersAllowed( event: any ): boolean {
    return ( event.charCode === 8 || event.charCode === 0 ) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  formIsValid(): boolean {
    // Here CompanyNameFieldMinLength is 3 and and companyCocodeFieldMinLength is 5. Add them as variables when used in at least one other place.
    return ( this.companySearchCriteria.companyName || '' ).length >= 3 || ( this.companySearchCriteria.companyCoCode || '' ).toString().length === 5;
  }

  formOnKeyDownFunction( event ) {
    if ( event.keyCode === 13 ) {
      this.searchCompanies();
    }
  }


}
