/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { from, from as observableFrom, of as observableOf, BehaviorSubject, Observable } from 'rxjs';

import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CustomerService } from '../api/customer.service';
import { BannerInput } from '../model/customer/bannerInput';
import { BillingOption } from '../model/customer/billingOption';
import { EndUserAgreement } from '../model/customer/endUserAgreement';
import { User } from '../model/customer/user';
import { UserDetail } from '../model/customer/userDetail';
import { UserInput } from '../model/customer/userInput';
import { UserRegistration } from '../model/customer/userRegistration';
import { OktaAuthService } from './okta-auth.service';

@Injectable( {
  providedIn: 'root',
} )
export class InsdataCustomerService {

  private static isUserAllowedForIndustry( user: User ): boolean {
    let hasRoleUser = false;
    let hasRoleAdmin = false;

    // Check current user's roles.  Don't allow those that has only ROLE_ADMIN
    for ( const authority of user.authorities ) {
      if ( authority.authorityCode === 'ROLE_USER' ) {
        hasRoleUser = true;
      }
      if ( authority.authorityCode === 'ROLE_ADMIN' ) {
        hasRoleAdmin = true;
      }
    }
    return hasRoleUser || !hasRoleAdmin;
  }

  public currentUser: User;
  private readonly _bannerInfo: BehaviorSubject<BannerInput> = new BehaviorSubject<BannerInput>(
    {
      bannerStatus: false,
      bannerText: '',
      userId: '',
    }
  );

  public get bannerInfo$(): Observable<BannerInput> {
    return this._bannerInfo.asObservable();
  }

  private currentUserDetail: UserDetail;

  constructor( private readonly customerService: CustomerService,
               private readonly oktaAuthService: OktaAuthService,
               protected readonly httpClient: HttpClient ) {
    this.getBanner().subscribe( ( data ) => {
      this._bannerInfo.next( data );
    }, () => {
      const banner: BannerInput = {
        bannerStatus: true,
        bannerText: 'The site is under maintenance.',
        userId: '',
      };
      this._bannerInfo.next( banner );
    } );
  }

  public fetchEndUserAgreement( observe?: 'body', reportProgress?: boolean ): Observable<EndUserAgreement>;
  public fetchEndUserAgreement( observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<EndUserAgreement>>;
  public fetchEndUserAgreement( observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<EndUserAgreement>>;
  public fetchEndUserAgreement( observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.customerService.fetchEndUserAgreement( observe, reportProgress );
  }

  public fetchUserById( userId: string, observe?: 'body', reportProgress?: boolean ): Observable<User>;
  public fetchUserById( userId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<User>>;
  public fetchUserById( userId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<User>>;
  public fetchUserById( userId: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.customerService.fetchUserById( userId, observe, reportProgress );
  }

  public fetchUserByName( userName: string, observe?: 'body', reportProgress?: boolean ): Observable<UserDetail>;
  public fetchUserByName( userName: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<UserDetail>>;
  public fetchUserByName( userName: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<UserDetail>>;
  public fetchUserByName( userName: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.customerService.fetchUserByName( userName, observe, reportProgress );
  }

  public fetchUserByPreferredUserId( preferredUserId: string, observe?: 'body', reportProgress?: boolean ): Observable<UserDetail>;
  public fetchUserByPreferredUserId( preferredUserId: string, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<UserDetail>>;
  public fetchUserByPreferredUserId( preferredUserId: string, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<UserDetail>>;
  public fetchUserByPreferredUserId( preferredUserId: string, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.customerService.fetchUserByPreferredUserId( preferredUserId, observe, reportProgress );
  }

  public getBanner( observe?: 'body', reportProgress?: boolean ): Observable<BannerInput>;
  public getBanner( observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<BannerInput>>;
  public getBanner( observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<BannerInput>>;
  public getBanner(): Observable<any> {
    return this.customerService.getBanner();
  }

  public registerUser( body: UserRegistration, observe?: 'body', reportProgress?: boolean ): Observable<UserDetail>;
  public registerUser( body: UserRegistration, observe?: 'response', reportProgress?: boolean ): Observable<HttpResponse<UserDetail>>;
  public registerUser( body: UserRegistration, observe?: 'events', reportProgress?: boolean ): Observable<HttpEvent<UserDetail>>;
  public registerUser( body: UserRegistration, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.customerService.registerUser( body, observe, reportProgress );
  }

  public updateUser( body: UserInput, observe?: 'body', reportProgress?: boolean ): Observable<UserDetail>;
  public updateUser( body: UserInput, observe: any = 'body', reportProgress = false ): Observable<any> {
    return this.customerService.updateUser( body, observe, reportProgress ).pipe( map( ( updatedUser: UserDetail ) => {
      this.currentUser = updatedUser.user;

      return updatedUser;
    } ) );
  }

  isCurrentUserDoNotCharge(): Observable<boolean> {
    return observableFrom( this.getCurrentUser().pipe( mergeMap( () => {
      return observableOf( this.currentUser.billingFlag === BillingOption.DoNotCharge );
    } ) ) );
  }

  public isCurrentUserStandard(): Observable<boolean> {
    return observableFrom( this.getCurrentUser().pipe( mergeMap( () => {
      return observableOf( this.currentUser.authorities[ 0 ].authorityCode === 'ROLE_USER' );
    } ) ) );
  }

  public isCurrentUserAllowedForIndustry(): Observable<boolean> {
    return observableFrom( this.getCurrentUser().pipe( mergeMap( () => {
      return observableOf( InsdataCustomerService.isUserAllowedForIndustry( this.currentUser ) );
    } ), catchError( ( err ) => {
      if ( err.status === 404 ) {
        return observableOf( true ); // They're a new user
      }
    } ) ) );
  }

  getCurrentUser(): Observable<User> {
    if ( this.currentUser ) {
      return observableOf( this.currentUser );
    } else {
      if ( this.oktaAuthService.userInfo ) {
        return this.setCurrentUser();
      } else {
        return this.setUserInfo();
      }
    }
  }

  setUserInfo(): Observable<User> {
    return observableFrom( this.oktaAuthService.fetchUserInfo() ).pipe( mergeMap( () => {
      return this.setCurrentUser();
    } ) );
  }

  setCurrentUser(): Observable<User> {
    return this.fetchUserByPreferredUserId( this.oktaAuthService.userInfo.preferred_username ).pipe( map( ( userDetail: UserDetail ) => {
      if ( this.oktaAuthService.userInfo.nickName !== userDetail.user.userName ) {
        this.updateEmailDueToLdapChange( userDetail );
      }
      this.currentUser = userDetail.user;
      return this.currentUser;
    } ) );
  }

  getCurrentUserDetail(): Observable<UserDetail> {
    if ( this.currentUserDetail ) {
      return observableOf( this.currentUserDetail );
    } else {
      return this.oktaAuthService.fetchUserInfo().pipe( mergeMap( ( presentInfo ) =>
        this.fetchUserByPreferredUserId( presentInfo.preferred_username ).pipe( map( ( userDetail: UserDetail ) => {
          this.currentUserDetail = userDetail;
          return this.currentUserDetail;
        } ) )
      ) );
    }
  }

  getCurrentUserRole(): Observable<string> {
    let role: string;
    if ( this.currentUser ) {
      for ( const authority of this.currentUser.authorities ) {
        if ( authority.authorityCode === 'ROLE_USER' ) {
          role = 'ROLE_USER';
        } else if ( authority.authorityCode === 'ROLE_ADMIN' ) {
          role = 'ROLE_ADMIN';
        } else if ( authority.authorityCode === 'ROLE_SPECIAL' ) {
          role = 'ROLE_SPECIAL';
        } else if ( authority.authorityCode === 'ROLE_ALL_ACCESS' ) {
          role = 'ROLE_ALL_ACCESS';
        }
      }
    }
    return observableOf( role );
  }

  private updateEmailDueToLdapChange( userDetail: UserDetail ) {
     this.oktaAuthService.fetchUserInfo().subscribe( ( presentUserInfo ) => {
      if ( presentUserInfo.preferred_username !== userDetail.user.userName ) {
        this.registerUser( { preferredUserId: presentUserInfo.preferred_username } ).subscribe( ( res ) => {
          this.currentUser = res.user;
        } );
      }
    } );
  }

}
