/*!
 * Copyright 2021 National Association of Insurance Commissioners
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InsdataCustomerService} from '../service/insdata-customer.service';

@Injectable()
export class BulkOrderManagerGuard implements CanActivate {
  constructor( private readonly insdataCustomerService: InsdataCustomerService ) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
    return this.insdataCustomerService.getCurrentUserRole().pipe( map( ( data ) => {
      return data === 'ROLE_ALL_ACCESS';
    } ) );
  }
}
