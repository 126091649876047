/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import {OktaAuth, UserClaims} from '@okta/okta-auth-js';
import {defer, from, Observable, ReplaySubject} from 'rxjs';

export declare type UserInfoClaims = UserClaims<{
  nickName: string;
  firstName: string;
}>;

@Injectable( {
  providedIn: 'root',
} )
export class OktaAuthService {
  public get isAuthenticated$(): Observable<boolean> {
    return this._isAuthenticated$;
  }

  public set isAuthenticated$( value: Observable<boolean> ) {
    this._isAuthenticated$ = value;
  }
  isAdminSubject: ReplaySubject<boolean> = new ReplaySubject( 1 );
  isLoggedInSubject: ReplaySubject<boolean> = new ReplaySubject( 1 );
  userInfo: UserInfoClaims;

  private _isAuthenticated$: Observable<boolean>;

  constructor( private readonly http: HttpClient,
               private readonly router: Router,
               @Inject( OKTA_AUTH ) private oktaAuth: OktaAuth ) {
  }

  initialize(): Promise<void> {
    return new Promise( ( resolve ) => {
      setTimeout( () => {
        resolve();
      }, 500 );
    } );
  }

  async fetchUserInfoAsync(): Promise<UserInfoClaims> {
    return this.fetchUser().then( ( claims ) => {
      this.userInfo = claims;
      return this.userInfo;
    } )
      .catch( ( err ) => {
        this.userInfo = null;
        return this.userInfo;
      } );
  }

  fetchUserInfo(): Observable<UserInfoClaims> {
    return defer( () => from<Promise<UserInfoClaims>>(
      this.fetchUserInfoAsync()
    ) );
  }

  async isLoggedIn(): Promise<boolean> {
    return await this.oktaAuth.isAuthenticated();
  }

  async startSigninMainWindow(): Promise<void> {
    await this.oktaAuth.signInWithRedirect();
  }

  async logout() {
    await this.oktaAuth.signOut().then( () => {
      this.isLoggedInSubject.next( false );
      this.isAdminSubject.next( false );
    } );
  }

  async startSignoutMainWindow() {
    sessionStorage.removeItem( 'companySearchCriteria' );
    sessionStorage.removeItem( 'companies' );
    try {
      await this.oktaAuth.signOut();
      setTimeout( null, 5000, () => {
        // Do nothing.
      } );
    } catch ( err ) {
      console.error( err );
    }
  }

  getAccessToken(): string {
    return this.oktaAuth.getAccessToken();
  }

  async getUser(): Promise<any> {
    let user: any;
    try {
      const isAuthenticated = await this.oktaAuth.isAuthenticated();
      if ( isAuthenticated ) {
        user = await this.oktaAuth.getUser();
      } else {
        console.warn( 'User is not authenticated' );
      }
    } catch ( error ) {
      console.error( 'Error fetching user:', error );
    }
    return user;
  }

  public getFirstName() {
    return this.fetchUserInfo();
  }

  private async fetchUser(): Promise<UserInfoClaims> {
    return await this.oktaAuth.getUser<{ nickName: string; firstName: string; }>()
      .then( ( user ) => this.userInfo = user )
      .catch( ( err ) => {
        console.error( err );
        return null;
      } );
  }
}

