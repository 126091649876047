<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
</div>

<div class="modal-body">
  <p>{{ body }}</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn" [class]="secondaryButtonClass"
          (click)="onSecondaryClick()">{{ secondaryButtonText }}
  </button>
  <button type="button" class="btn" [class]="primaryButtonClass" (click)="onPrimaryClick()">{{ primaryButtonText }}
  </button>
</div>
