/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { InsdataLoadingSpinnerService } from './service/insdata-loading-spinner.service';
import { OktaAuthService } from './service/okta-auth.service';

@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
} )
export class AppComponent implements OnInit {

  constructor(
    public oktaAuthService: OktaAuthService,
    private readonly router: Router,
    private readonly insdataLoadingSpinnerService: InsdataLoadingSpinnerService,
    private _oktaStateService: OktaAuthStateService
  ) {
    this.router.events.subscribe( ( e ) => {
      this.navigationInterceptor( e );
    } );
  }

  ngOnInit(): void {
    this.oktaAuthService.isAuthenticated$ = this._oktaStateService.authState$.pipe(
      filter( ( s: AuthState ) => !!s ),
      map( ( s: AuthState ) => s.isAuthenticated ?? false )
    );
  }

  /**
   * The purpose of this function is to prevent the insdata-messages to show up twice.  This function is being used
   * inside the insdata-message tag of this app.component.html template.  There is another one under the header.
   * This function asks if you're anywhere but the bootstrapped app component (the one you're seeing right now).  If you're anywhere else
   * then the insdata-message tag in the app.component.html will not render.
   * @returns {boolean}
   */
  navigationInterceptor( event: any ): void {
    const isLoading = this.insdataLoadingSpinnerService.getLoadingSpinnerSubject().value;
    if ( isLoading ) {
      if ( event instanceof NavigationEnd ) {
        this.insdataLoadingSpinnerService.hideLoadingSpinner();
      } else if ( event instanceof NavigationCancel ) {
        this.insdataLoadingSpinnerService.hideLoadingSpinner();
      } else if ( event instanceof NavigationError ) {
        this.insdataLoadingSpinnerService.hideLoadingSpinner();
      }
    } else {
      if ( event instanceof NavigationStart ) {
        this.insdataLoadingSpinnerService.showLoadingSpinner();
      }
    }
  }
}
