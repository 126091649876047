/*
 * Copyright 2025 National Association of Insurance Commissioners
 */
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { faThLarge, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ApiAddress, NcuiAboutModalComponentListItem, NcuiHeaderDropdownOption } from '../../model/ncui-interfaces';
import { NcuiAddressService } from '../../service/ncui-address.service';
import { NcuiImageService } from '../../service/ncui-image.service';

@Component( {
  selector: 'app-ncui-header',
  templateUrl: './ncui-header.component.html',
  styleUrls: [ './ncui-header.component.scss' ],
  encapsulation: ViewEncapsulation.None,
} )
export class NcuiHeaderComponent implements OnInit {
  @Input() hideAppHeader = false; // If true, the blue gradient app header is hidden
  @Input() appTitle: string;
  @Input() appSubtitle: string;
  @Input() appLogo: string;
  @Input() realm: string;
  @Input() loggedInUser: any;
  @Input() dropdownMenuItems: NcuiHeaderDropdownOption[] = [];
  @Input() helpModalHeaderText: string;
  @Input() helpModalContentHtml: string;
  @Input() aboutModalComponentListItems: NcuiAboutModalComponentListItem[] = [];
  @Output() login: EventEmitter<any> = new EventEmitter();
  @Output() logout: EventEmitter<any> = new EventEmitter();
  @Output() dropdownMenuItemClick: EventEmitter<any> = new EventEmitter();

  faThLarge: IconDefinition = faThLarge;
  images: NcuiImageService;
  appLogoSrc: string;
  currentUrl: string;
  mainOffice: any = {
    hoursOfOperation: '8 a.m. to 5 p.m. (CT)',
    daysOfOperation: 'Monday - Friday',
    phone: '(816) 783-8500',
    fax: '(816) 460-7456',
    email: 'help@naic.org',
  };

  constructor(
    private nis: NcuiImageService,
    private ncuiAddressService: NcuiAddressService
  ) {
  }

  ngOnInit() {
    this.images = this.nis;
    this.appLogoSrc = this.appLogo || this.images.defaultApp;
    this.currentUrl = window.location.href;
    this.helpModalHeaderText = this.helpModalHeaderText || 'Help Desk Information';

    if ( !this.helpModalContentHtml ) { //  Default help modal text if none is provided
      this.setHelpModalContentHtml();

      this.ncuiAddressService.getAddresses().subscribe( ( addresses ) => {
        function formatPhone( ph: string ) {
          return `(${ ph.slice( 0, 3 ) }) ${ ph.slice( 3, 6 ) }-${ ph.slice( 6 ) }`;
        }

        const addr: ApiAddress = addresses[ 0 ];
        this.mainOffice = {
          hoursOfOperation: addr.switchBoardHours,
          daysOfOperation: addr.switchBoardDays,
          phone: formatPhone( addr.phone ),
          fax: formatPhone( addr.fax ),
          email: addr.email,
        };

        this.setHelpModalContentHtml();
      } );
    }
  }

  getRealm(): string {
    if ( this.realm ) {
      return this.realm;
    }

    const url = this.currentUrl;
    let realm;

    if ( url.indexOf( '.naic.org' ) > -1 ) {
      realm = url.slice( 0, url.indexOf( '.naic.org' ) ).slice( url.lastIndexOf( '-' ) + 1 );
    }

    switch ( realm ) {
      case 'local': // Fall through
      case 'localhost': // Fall through
        return 'localhost';
      case 'dev': // Fall through
      case 'dvlp':
        return 'dvlp';
      case 'int':
        return 'int';
      case 'qa':
        return 'qa';
      case 'beta':
        return 'beta';
      case 'prod': // Fall through
      case 'production': // Fall through
      default:
        return 'prod';
    }
  }

  getMyProfileUrl(): string {
    return `https://eapps${ this.getRealmSuffix() }.naic.org/my-profile/?referrer=${ encodeURI( window.location.href ) }`;
  }

  getMyNaicUrl(): string {
    return `http://eapps${ this.getRealmSuffix() }.naic.org/lhub/products.html`;
  }

  ncuiLogin(): void {
    this.login.emit();
  }

  ncuiLogout(): void {
    this.logout.emit();
  }

  emitDropdownOption( clickedItem ): void {
    this.dropdownMenuItemClick.emit( clickedItem );
  }

  private setHelpModalContentHtml(): void {
    this.helpModalContentHtml = `
      <div class="row">
        <div class="col col-sm-6 hours">
          <b>Business Hours</b>
          <p class="helpDeskOperationTime">${ this.mainOffice.hoursOfOperation }<br>${ this.mainOffice.daysOfOperation }</p>
        </div><!-- /.col -->
        <div class="col col-sm-6">
          <p class="helpDeskContactInfo">
            <span class="phone"><b>Phone:</b> <span class="value">${ this.mainOffice.phone }</span><br></span>
            <span class="fax"><b>Fax:</b> <span class="value">${ this.mainOffice.fax }</span><br></span>
            <span class="email"><b>Email:</b> <a class="email__link" href="mailto:${ this.mainOffice.email }"
              id="cui-helpdesk-email"><span class="value">${ this.mainOffice.email }</span></a></span>
          </p>
        </div><!-- /.col -->
      </div><!-- /.row -->
    `;
  }


  private getRealmSuffix(): string {
    if ( this.getRealm() === 'beta' ) {
      return `-${ this.getRealm() }`;
    }
    if ( this.getRealm() && this.getRealm() !== 'prod' ) {
      return `.${ this.getRealm() }`;
    }
    return '';
  }
}
