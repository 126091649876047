<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<header id="GBL_Common_Header" class="common-ui">
  <div id="system-notifications"></div>
  <div class="alert-wrapper alertBar"></div>

  <nav id="GBL_Header_Navigation"
       class="navbar navbar-light navbar-expand-md global-nav hidden-print sticky-top navbar-static-top"
       role="navigation">
    <a href="http://www.naic.org/" id="cui-logo-anchor" class="logo-naic navbar-brand">
      <img class="brandmark" width="67" [src]="images.brandLogo" alt="NAIC Logo">
      <img class="tagline" width="123" [src]="images.tagLine" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" id="cui-nav-toggle"
            data-bs-target="#globalNav-toggle" aria-controls="globalNav-toggle"
            aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="globalNav-toggle">
      <div class="wrapper-navLinks nav navbar-nav ms-auto">
        <a href="#" class="aboutLink" title="About this app and Common UI implementation" data-bs-toggle="modal"
           data-bs-target="#modalAbout" id="cui-about">About</a>
        <a href="#" class="helpLink" title="Get Help Information" data-bs-toggle="modal" data-bs-target="#modalHelp"
           id="cui-help">Help</a>
        <a [href]="getMyNaicUrl()" class="block hubLink" title="Navigate to myNAIC main page" id="cui-hub"><span
          class="sr-only">myNAIC</span>
          <fa-icon [icon]="faThLarge"></fa-icon>
        </a><!-- /.hubLink -->

        <div class="hiddenIfCreateAccountActive">
          <div *ngIf="!(loggedInUser || {}).name" class="loginLink" title="Login with Your NAIC User Account">
            <div *ngIf="dropdownMenuItems.length" class="dropdown">
              <a href="#" class="block accountLink dropdown-toggle" data-bs-toggle="dropdown"
                 id="cui-login-dropdown-options-toggle">
                <img [src]="images.shield16" class="icon-user" alt="myNAIC Shield Logo">
                Login/Signup
              </a><!-- /.accountLink -->
              <ul class="dropdown-menu" role="menu">
                <ng-container *ngFor="let item of dropdownMenuItems; let i = index">
                  <li class="dropdown-item">
                    <a *ngIf="item.emitFunctionOnClick" class="dropdown-menu-items" [id]="'cui-dropdown-menu-item-' + i"
                       (click)="emitDropdownOption(item)" href="#">{{ item.text }}</a>
                    <a *ngIf="!item.emitFunctionOnClick" class="dropdown-menu-items"
                       [id]="'cui-dropdown-menu-item-' + i"
                       [href]="item.url || '#'">{{ item.text }}</a>
                  </li>
                  <li *ngIf="dropdownMenuItems.length - 1 > i" class="dropdown-divider"></li>
                </ng-container>
              </ul>
            </div>
            <!-- FIXME:: convert to button -->
            <a *ngIf="!dropdownMenuItems.length" (click)="ncuiLogin()" class="block accountLink" id="cui-login">
              <img [src]="images.shield16" class="icon-user" alt="myNAIC Shield Logo">
              Login
            </a><!-- /.accountLink -->
          </div>
          <div *ngIf="(loggedInUser || {}).name" class="dropdown loggedin" title="Your NAIC User Account">
            <a href="#" class="block accountLink dropdown-toggle" data-bs-toggle="dropdown"
               id="cui-account-dropdown-toggle">
              <img [src]="images.shield16" class="icon-user" alt="myNAIC Shield Logo">
              <span class="loggedInUser">{{ loggedInUser.name }}</span>
            </a><!-- /.accountLink -->
            <ul class="dropdown-menu" role="menu">
              <li class="dropdown-item"><a class="myProfileUrl" [href]="getMyProfileUrl()" id="cui-my-profile">My
                Profile</a></li>
              <li class="dropdown-divider"></li>
              <li class="dropdown-item">
                <!-- FIXME:: convert to button -->
                <a class="logoutUrl" (click)="ncuiLogout()" id="cui-logout">Log Out</a>
              </li>
            </ul>
          </div><!-- /.dropdown -->
        </div>

        <div class="dropdown hidden shownIfCreateAccountActive" hidden title="Your NAIC User Account">
          <a href="#" class="block accountLink dropdown-toggle" data-bs-toggle="dropdown"
             id="cui-account-dropdown-toggle-with-create-account">
            <img [src]="images.shield16" class="icon-user" alt="myNAIC Shield Logo">
            <span class="loginLink accountText">Account</span><span class="loggedin loggedInUser"></span>
          </a><!-- /.accountLink -->
          <ul class="dropdown-menu" role="menu">
            <li class="dropdown-item loginLink"><a href="#" class="accountLink"
                                                   id="cui-login-with-create-account">Login</a></li>
            <li class="dropdown-item loginLink"><a href="#" class="accountSignUp hidden"
                                                   id="cui-signup-with-create-account">Signup</a></li>

            <li class="dropdown-item loggedin hidden"><a class="myProfileUrl" href="#"
                                                         id="cui-my-profile-with-create-account">My Profile</a></li>
            <li class="divider loggedin hidden"></li>
            <li class="dropdown-item loggedin hidden">
              <!-- FIXME:: convert to button -->
              <a class="logoutUrl" (click)="ncuiLogout()"
                 id="cui-logout-with-create-account">Log Out</a></li>
          </ul>
        </div><!-- /.dropdown -->
      </div><!-- /.wrapper-navLinks -->
    </div><!-- navbar-collapse -->
  </nav>

  <div id="GBL_App_Header" class="app-banner hidden-print" *ngIf="!hideAppHeader">
    <div class="wrapper-banner-content clearfix">
      <div class="wrapper-banner-logo-title">
        <div class="wrapper-app-logo">
          <img class="app-logo app-home" [src]="appLogoSrc" alt="Application Logo">
        </div>
        <!-- /.wrapper-app-logo -->
        <div class="app-title">
          <h1 class="app-home">{{ appTitle }}</h1>
          <p class="app-subtitle app-home" *ngIf="appSubtitle && appSubtitle.length">{{ appSubtitle }}</p>
        </div>
        <!-- /.app-title -->
      </div>
      <!-- /.wrapper-banner-logo-title -->
      <!-- FIXME:: Move inline style to style sheet -->
      <div class="wrapper-banner-short" style="display:none">
        <span class="title-fallback app-home">{{ appTitle }}</span> <span class="subtitle-fallback app-home"
                                                                          *ngIf="appSubtitle && appSubtitle.length">{{ appSubtitle }}</span>
      </div>
    </div><!-- /.wrapper-banner-content -->
  </div>
</header>


<div class="modal fade" id="modalHelp" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 hidden>Help Desk</h3>
        <h4 class="modal-title helpDeskModalTitle">{{ helpModalHeaderText }}</h4>
        <!-- <div [innerHTML]="modalHelpHeaderHtml"></div> -->
        <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true" id="cui-help-modal-header-close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [innerHTML]="helpModalContentHtml"></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-bs-dismiss="modal" id="cui-help-modal-close">Close</button>
      </div>
    </div><!-- /.modal-content -->
  </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div class="modal modal-about fade" id="modalAbout" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="hidden">About</h3>
        <h4 class="modal-title aboutModalTitle">About</h4>
        <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true"
                id="cui-about-modal-header-close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row environment">
          <div class="col-sm">
            <div class="realm">
              <span class="key">Realm</span>
              <span class="value">{{ getRealm() }}</span>
            </div>
          </div>
          <div class="col-sm">
            <div class="node">
              <span class="key">Node</span>
              <span class="value">Unknown</span>
            </div>
          </div>
        </div>
        <table class="table" id="cui-module-info">
          <thead>
          <tr>
            <th>Module Name</th>
            <th>Version</th>
            <th>Last Build</th>
          </tr>
          </thead>
          <tbody id="component-list">
          <tr *ngFor="let comp of aboutModalComponentListItems">
            <td class="component-name">{{ comp.name }}</td>
            <td class="component-version">{{ comp.version }}</td>
            <td class="component-build-date">{{ comp.buildDate }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-bs-dismiss="modal" id="cui-about-modal-footer-close">Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="myModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-bs-dismiss="modal" aria-hidden="true" id="cui-my-modal-header-close">
          &times;
        </button>
        <h4 class="modal-title">&nbsp;</h4>
      </div>
      <div class="modal-body">
        <p></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-bs-dismiss="modal" id="cui-my-modal-footer-close">Close
        </button>
      </div>
    </div>
  </div>
</div>

<div id="realm-indicator" [class]="getRealm()">{{ getRealm() }}</div>
