/**
 * NAIC InsData Order Service
 * NAIC InsData Order service
 *
 * OpenAPI spec version: 1.0.0
 * Contact: InsDataStaff@naic.org
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CancelOrderMessage } from '../model/order/cancelOrderMessage';
import { CoCodeLists } from '../model/order/coCodeLists';
import { CoCodeListsDetail } from '../model/order/coCodeListsDetail';
import { CocodeDownloadCount } from '../model/order/cocodeDownloadCount';
import { CocodeLoadOrRemoveResult } from '../model/order/cocodeLoadOrRemoveResult';
import { OrderDetail } from '../model/order/orderDetail';
import { OrderInput } from '../model/order/orderInput';
import { OrdersSearchResult } from '../model/order/ordersSearchResult';
import { ProductDataKey } from '../model/order/productDataKey';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrderService {

    protected basePath = 'https://localhost:8451';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add a cocode to a User&#39;s cocode list
     *
     * @param userId The user UUID for the special user.
     * @param filingYear Filing Year.
     * @param cocode NAIC Company CoCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCocodeToCocodeList(userId: string, filingYear: number, cocode: number, observe?: 'body', reportProgress?: boolean): Observable<CocodeLoadOrRemoveResult>;
    public addCocodeToCocodeList(userId: string, filingYear: number, cocode: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CocodeLoadOrRemoveResult>>;
    public addCocodeToCocodeList(userId: string, filingYear: number, cocode: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CocodeLoadOrRemoveResult>>;
    public addCocodeToCocodeList(userId: string, filingYear: number, cocode: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addCocodeToCocodeList.');
        }

        if (filingYear === null || filingYear === undefined) {
            throw new Error('Required parameter filingYear was null or undefined when calling addCocodeToCocodeList.');
        }

        if (cocode === null || cocode === undefined) {
            throw new Error('Required parameter cocode was null or undefined when calling addCocodeToCocodeList.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<CocodeLoadOrRemoveResult>(`${this.basePath}/orders/cocodelists/companyCode/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(filingYear))}/${encodeURIComponent(String(cocode))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add an order for a user
     *
     * @param userId User UUID for the order.
     * @param body Order to add for the user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrder(userId: string, body: OrderInput, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public addOrder(userId: string, body: OrderInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public addOrder(userId: string, body: OrderInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public addOrder(userId: string, body: OrderInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addOrder.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/orders/${encodeURIComponent(String(userId))}`,
            body,
            {
                responseType: 'text',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add an order for a user and product data key
     *
     * @param userId User UUID for the order
     * @param body ProductDataKey
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOrderByProductDataKey(userId: string, body: ProductDataKey, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public addOrderByProductDataKey(userId: string, body: ProductDataKey, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public addOrderByProductDataKey(userId: string, body: ProductDataKey, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public addOrderByProductDataKey(userId: string, body: ProductDataKey, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addOrderByProductDataKey.');
        }

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addOrderByProductDataKey.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/orders/productDataKey/${encodeURIComponent(String(userId))}`,
            body,
            {
                responseType: 'text',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Cancel an order (admin only operation).
     *
     * @param userId The user UUID for the user who purchased the order.
     * @param orderId The order UUID for the order that needs to be cancelled.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cancelOrder(userId: string, orderId: string, observe?: 'body', reportProgress?: boolean): Observable<CancelOrderMessage>;
    public cancelOrder(userId: string, orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CancelOrderMessage>>;
    public cancelOrder(userId: string, orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CancelOrderMessage>>;
    public cancelOrder(userId: string, orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling cancelOrder.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling cancelOrder.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CancelOrderMessage>(`${this.basePath}/orders/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create a user&#39;s cocode list from a file.
     *
     * @param userId The user UUID for the special user.
     * @param filingYear Filing Year.
     * @param uploadCsvfile The file to upload.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCocodeList(userId: string, filingYear: number, uploadCsvfile: Blob, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public createCocodeList(userId: string, filingYear: number, uploadCsvfile: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public createCocodeList(userId: string, filingYear: number, uploadCsvfile: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public createCocodeList(userId: string, filingYear: number, uploadCsvfile: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling createCocodeList.');
        }

        if (filingYear === null || filingYear === undefined) {
            throw new Error('Required parameter filingYear was null or undefined when calling createCocodeList.');
        }

        if (uploadCsvfile === null || uploadCsvfile === undefined) {
            throw new Error('Required parameter uploadCsvfile was null or undefined when calling createCocodeList.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (uploadCsvfile !== undefined) {
            formParams = formParams.append('uploadCsvfile', <any>uploadCsvfile) || formParams;
        }

        return this.httpClient.post<boolean>(`${this.basePath}/orders/cocodelists/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(filingYear))}`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds cocodes to a User&#39;s cocode list based off of statement type
     *
     * @param userId The user UUID for the special user.
     * @param filingYear Filing Year.
     * @param statementType The statement type of cocodes to be added to the User&#39;s cocode list
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCocodeListByStatementType(userId: string, filingYear: number, statementType: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public createCocodeListByStatementType(userId: string, filingYear: number, statementType: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public createCocodeListByStatementType(userId: string, filingYear: number, statementType: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public createCocodeListByStatementType(userId: string, filingYear: number, statementType: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling createCocodeListByStatementType.');
        }

        if (filingYear === null || filingYear === undefined) {
            throw new Error('Required parameter filingYear was null or undefined when calling createCocodeListByStatementType.');
        }

        if (statementType === null || statementType === undefined) {
            throw new Error('Required parameter statementType was null or undefined when calling createCocodeListByStatementType.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/orders/cocodelists/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(filingYear))}/${encodeURIComponent(String(statementType))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes cocode from cocode list of specified year.
     *
     * @param userId The user UUID for the user who purchased the order.
     * @param filingYear Filing Year.
     * @param cocode NAIC Company Code.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCocode(userId: string, filingYear: number, cocode: number, observe?: 'body', reportProgress?: boolean): Observable<CocodeLoadOrRemoveResult>;
    public deleteCocode(userId: string, filingYear: number, cocode: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CocodeLoadOrRemoveResult>>;
    public deleteCocode(userId: string, filingYear: number, cocode: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CocodeLoadOrRemoveResult>>;
    public deleteCocode(userId: string, filingYear: number, cocode: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteCocode.');
        }

        if (filingYear === null || filingYear === undefined) {
            throw new Error('Required parameter filingYear was null or undefined when calling deleteCocode.');
        }

        if (cocode === null || cocode === undefined) {
            throw new Error('Required parameter cocode was null or undefined when calling deleteCocode.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<CocodeLoadOrRemoveResult>(`${this.basePath}/orders/cocodelists/companyCode/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(filingYear))}/${encodeURIComponent(String(cocode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete or purge a cocode list for specified year and user.
     *
     * @param userId The user UUID for the user.
     * @param filingYear Filing Year.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCocodeList(userId: string, filingYear: number, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public deleteCocodeList(userId: string, filingYear: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public deleteCocodeList(userId: string, filingYear: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public deleteCocodeList(userId: string, filingYear: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deleteCocodeList.');
        }

        if (filingYear === null || filingYear === undefined) {
            throw new Error('Required parameter filingYear was null or undefined when calling deleteCocodeList.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<number>(`${this.basePath}/orders/cocodelists/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(filingYear))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download a user&#39;s cocode list file link (Admin and Special User Only)
     *
     * @param userId The user UUID for the user.
     * @param orderYear Order Year.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadCocodeListExport(userId: string, orderYear: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public downloadCocodeListExport(userId: string, orderYear: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public downloadCocodeListExport(userId: string, orderYear: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public downloadCocodeListExport(userId: string, orderYear: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadCocodeListExport.');
        }

        if (orderYear === null || orderYear === undefined) {
            throw new Error('Required parameter orderYear was null or undefined when calling downloadCocodeListExport.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/orders/cocodelistsdownload/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(orderYear))}`,
            {
              responseType: 'text',
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch a user&#39;s cocode list for the specified year
     *
     * @param userId The user UUID for the user.
     * @param filingYear Filing Year.
     * @param itemStatusFilter Item status filter.
     * @param start start item
     * @param limit item limit
     * @param histStart history start item
     * @param histLimit history item limit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchCocodeList(userId: string, filingYear: number, itemStatusFilter?: 'none' | 'pending' | 'available' | 'nofiling' | 'locked', start?: number, limit?: number, histStart?: number, histLimit?: number, observe?: 'body', reportProgress?: boolean): Observable<CoCodeListsDetail>;
    public fetchCocodeList(userId: string, filingYear: number, itemStatusFilter?: 'none' | 'pending' | 'available' | 'nofiling' | 'locked', start?: number, limit?: number, histStart?: number, histLimit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CoCodeListsDetail>>;
    public fetchCocodeList(userId: string, filingYear: number, itemStatusFilter?: 'none' | 'pending' | 'available' | 'nofiling' | 'locked', start?: number, limit?: number, histStart?: number, histLimit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CoCodeListsDetail>>;
    public fetchCocodeList(userId: string, filingYear: number, itemStatusFilter?: 'none' | 'pending' | 'available' | 'nofiling' | 'locked', start?: number, limit?: number, histStart?: number, histLimit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling fetchCocodeList.');
        }

        if (filingYear === null || filingYear === undefined) {
            throw new Error('Required parameter filingYear was null or undefined when calling fetchCocodeList.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemStatusFilter !== undefined && itemStatusFilter !== null) {
            queryParameters = queryParameters.set('itemStatusFilter', <any>itemStatusFilter);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (histStart !== undefined && histStart !== null) {
            queryParameters = queryParameters.set('histStart', <any>histStart);
        }
        if (histLimit !== undefined && histLimit !== null) {
            queryParameters = queryParameters.set('histLimit', <any>histLimit);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CoCodeListsDetail>(`${this.basePath}/orders/cocodelists/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(filingYear))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch user&#39;s all cocode lists
     *
     * @param userId The user UUID for the special user.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchCocodeLists(userId: string, observe?: 'body', reportProgress?: boolean): Observable<CoCodeLists>;
    public fetchCocodeLists(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CoCodeLists>>;
    public fetchCocodeLists(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CoCodeLists>>;
    public fetchCocodeLists(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling fetchCocodeLists.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CoCodeLists>(`${this.basePath}/orders/cocodelists/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch the detail for a particular order.
     *
     * @param userId The user UUID for the user who purchased the order.
     * @param orderId The order UUID for the order that needs to be fetched.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchOrderDetail(userId: string, orderId: string, observe?: 'body', reportProgress?: boolean): Observable<OrderDetail>;
    public fetchOrderDetail(userId: string, orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderDetail>>;
    public fetchOrderDetail(userId: string, orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderDetail>>;
    public fetchOrderDetail(userId: string, orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling fetchOrderDetail.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling fetchOrderDetail.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrderDetail>(`${this.basePath}/orders/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get an array of product keys for an order
     *
     * @param userId The user UUID for the user who purchased the order.
     * @param orderId The order UUID for the order that needs to be fetched.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchOrderProductKeys(userId: string, orderId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProductDataKey>>;
    public fetchOrderProductKeys(userId: string, orderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductDataKey>>>;
    public fetchOrderProductKeys(userId: string, orderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductDataKey>>>;
    public fetchOrderProductKeys(userId: string, orderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling fetchOrderProductKeys.');
        }

        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling fetchOrderProductKeys.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProductDataKey>>(`${this.basePath}/orders/orderProductKeys/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(orderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Fetch orders for a user
     *
     * @param userId The user UUID for the user who placed the orders.
     * @param orderType Order Type
     * @param start start item
     * @param limit item limit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchOrders(userId: string, orderType?: 'ALL' | 'CART' | 'BULK', start?: number, limit?: number, observe?: 'body', reportProgress?: boolean): Observable<OrdersSearchResult>;
    public fetchOrders(userId: string, orderType?: 'ALL' | 'CART' | 'BULK', start?: number, limit?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersSearchResult>>;
    public fetchOrders(userId: string, orderType?: 'ALL' | 'CART' | 'BULK', start?: number, limit?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersSearchResult>>;
    public fetchOrders(userId: string, orderType?: 'ALL' | 'CART' | 'BULK', start?: number, limit?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling fetchOrders.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orderType !== undefined && orderType !== null) {
            queryParameters = queryParameters.set('orderType', <any>orderType);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrdersSearchResult>(`${this.basePath}/orders/${encodeURIComponent(String(userId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * user&#39;s cocode download list from a filingYear.
     *
     * @param userId The user UUID for the user who purchased the order.
     * @param filingYear Filing Year.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fetchOrdersDownloadCount(userId: string, filingYear: number, observe?: 'body', reportProgress?: boolean): Observable<CocodeDownloadCount>;
    public fetchOrdersDownloadCount(userId: string, filingYear: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CocodeDownloadCount>>;
    public fetchOrdersDownloadCount(userId: string, filingYear: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CocodeDownloadCount>>;
    public fetchOrdersDownloadCount(userId: string, filingYear: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling fetchOrdersDownloadCount.');
        }

        if (filingYear === null || filingYear === undefined) {
            throw new Error('Required parameter filingYear was null or undefined when calling fetchOrdersDownloadCount.');
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CocodeDownloadCount>(`${this.basePath}/orders/userorderdownloads/${encodeURIComponent(String(userId))}/${encodeURIComponent(String(filingYear))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create orders report (admin only)
     *
     * @param orderId Order UUID (partial)
     * @param orderType Order Type
     * @param transactionId Stripe Transaction Id (partial)
     * @param startPurchaseDate Start Purchase Date Range
     * @param endPurchaseDate End Purchase Date Range
     * @param firstName User First Name (partial)
     * @param lastName User Last Name (partial)
     * @param userName User Login Name (partial)
     * @param companyCoCode Company Co Code (exact)
     * @param statementYear Statement Year (exact)
     * @param submissionFilingPeriod Submission Filing Period (exact)
     * @param isKey Only applicable if submission filing period is &#39;Annual&#39; (exact)
     * @param statementTypeDescription Statement Type Description (exact)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ordersReport(orderId?: string, orderType?: 'ALL' | 'CART' | 'BULK', transactionId?: string, startPurchaseDate?: Date, endPurchaseDate?: Date, firstName?: string, lastName?: string, userName?: string, companyCoCode?: number, statementYear?: number, submissionFilingPeriod?: string, isKey?: boolean, statementTypeDescription?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public ordersReport(orderId?: string, orderType?: 'ALL' | 'CART' | 'BULK', transactionId?: string, startPurchaseDate?: Date, endPurchaseDate?: Date, firstName?: string, lastName?: string, userName?: string, companyCoCode?: number, statementYear?: number, submissionFilingPeriod?: string, isKey?: boolean, statementTypeDescription?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public ordersReport(orderId?: string, orderType?: 'ALL' | 'CART' | 'BULK', transactionId?: string, startPurchaseDate?: Date, endPurchaseDate?: Date, firstName?: string, lastName?: string, userName?: string, companyCoCode?: number, statementYear?: number, submissionFilingPeriod?: string, isKey?: boolean, statementTypeDescription?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public ordersReport(orderId?: string, orderType?: 'ALL' | 'CART' | 'BULK', transactionId?: string, startPurchaseDate?: Date, endPurchaseDate?: Date, firstName?: string, lastName?: string, userName?: string, companyCoCode?: number, statementYear?: number, submissionFilingPeriod?: string, isKey?: boolean, statementTypeDescription?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {














        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (orderType !== undefined && orderType !== null) {
            queryParameters = queryParameters.set('orderType', <any>orderType);
        }
        if (transactionId !== undefined && transactionId !== null) {
            queryParameters = queryParameters.set('transactionId', <any>transactionId);
        }
        if (startPurchaseDate !== undefined && startPurchaseDate !== null) {
            queryParameters = queryParameters.set('startPurchaseDate', <any>startPurchaseDate.toISOString());
        }
        if (endPurchaseDate !== undefined && endPurchaseDate !== null) {
            queryParameters = queryParameters.set('endPurchaseDate', <any>endPurchaseDate.toISOString());
        }
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (userName !== undefined && userName !== null) {
            queryParameters = queryParameters.set('userName', <any>userName);
        }
        if (companyCoCode !== undefined && companyCoCode !== null) {
            queryParameters = queryParameters.set('companyCoCode', <any>companyCoCode);
        }
        if (statementYear !== undefined && statementYear !== null) {
            queryParameters = queryParameters.set('statementYear', <any>statementYear);
        }
        if (submissionFilingPeriod !== undefined && submissionFilingPeriod !== null) {
            queryParameters = queryParameters.set('submissionFilingPeriod', <any>submissionFilingPeriod);
        }
        if (isKey !== undefined && isKey !== null) {
            queryParameters = queryParameters.set('isKey', <any>isKey);
        }
        if (statementTypeDescription !== undefined && statementTypeDescription !== null) {
            queryParameters = queryParameters.set('statementTypeDescription', <any>statementTypeDescription);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/orders/ordersReport`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search for user orders
     *
     * @param orderId Order UUID (partial)
     * @param orderType Order Type
     * @param transactionId Stripe Transaction Id (partial)
     * @param startPurchaseDate Start Purchase Date Range
     * @param endPurchaseDate End Purchase Date Range
     * @param firstName User First Name (partial)
     * @param lastName User Last Name (partial)
     * @param userName User Login Name (partial)
     * @param companyCoCode Company Co Code (exact)
     * @param statementYear Statement Year (exact)
     * @param submissionFilingPeriod Submission Filing Period (exact)
     * @param isKey Only applicable if submission filing period is &#39;Annual&#39; (exact)
     * @param statementTypeDescription Statement Type Description (exact)
     * @param start start item
     * @param limit item limit
     * @param sort Column to sort on
     * @param sortOrder Sort Order
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchOrders(orderId?: string, orderType?: 'ALL' | 'CART' | 'BULK', transactionId?: string, startPurchaseDate?: Date, endPurchaseDate?: Date, firstName?: string, lastName?: string, userName?: string, companyCoCode?: number, statementYear?: number, submissionFilingPeriod?: string, isKey?: boolean, statementTypeDescription?: string, start?: number, limit?: number, sort?: 'orderId' | 'transactionId' | 'purchaseDate' | 'companyCoCode' | 'statementYear' | 'submissionFilingPeriod' | 'isKey', sortOrder?: 'ASC' | 'DESC', observe?: 'body', reportProgress?: boolean): Observable<OrdersSearchResult>;
    public searchOrders(orderId?: string, orderType?: 'ALL' | 'CART' | 'BULK', transactionId?: string, startPurchaseDate?: Date, endPurchaseDate?: Date, firstName?: string, lastName?: string, userName?: string, companyCoCode?: number, statementYear?: number, submissionFilingPeriod?: string, isKey?: boolean, statementTypeDescription?: string, start?: number, limit?: number, sort?: 'orderId' | 'transactionId' | 'purchaseDate' | 'companyCoCode' | 'statementYear' | 'submissionFilingPeriod' | 'isKey', sortOrder?: 'ASC' | 'DESC', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdersSearchResult>>;
    public searchOrders(orderId?: string, orderType?: 'ALL' | 'CART' | 'BULK', transactionId?: string, startPurchaseDate?: Date, endPurchaseDate?: Date, firstName?: string, lastName?: string, userName?: string, companyCoCode?: number, statementYear?: number, submissionFilingPeriod?: string, isKey?: boolean, statementTypeDescription?: string, start?: number, limit?: number, sort?: 'orderId' | 'transactionId' | 'purchaseDate' | 'companyCoCode' | 'statementYear' | 'submissionFilingPeriod' | 'isKey', sortOrder?: 'ASC' | 'DESC', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdersSearchResult>>;
    public searchOrders(orderId?: string, orderType?: 'ALL' | 'CART' | 'BULK', transactionId?: string, startPurchaseDate?: Date, endPurchaseDate?: Date, firstName?: string, lastName?: string, userName?: string, companyCoCode?: number, statementYear?: number, submissionFilingPeriod?: string, isKey?: boolean, statementTypeDescription?: string, start?: number, limit?: number, sort?: 'orderId' | 'transactionId' | 'purchaseDate' | 'companyCoCode' | 'statementYear' | 'submissionFilingPeriod' | 'isKey', sortOrder?: 'ASC' | 'DESC', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (orderId !== undefined && orderId !== null) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (orderType !== undefined && orderType !== null) {
            queryParameters = queryParameters.set('orderType', <any>orderType);
        }
        if (transactionId !== undefined && transactionId !== null) {
            queryParameters = queryParameters.set('transactionId', <any>transactionId);
        }
        if (startPurchaseDate !== undefined && startPurchaseDate !== null) {
            queryParameters = queryParameters.set('startPurchaseDate', <any>startPurchaseDate.toISOString());
        }
        if (endPurchaseDate !== undefined && endPurchaseDate !== null) {
            queryParameters = queryParameters.set('endPurchaseDate', <any>endPurchaseDate.toISOString());
        }
        if (firstName !== undefined && firstName !== null) {
            queryParameters = queryParameters.set('firstName', <any>firstName);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (userName !== undefined && userName !== null) {
            queryParameters = queryParameters.set('userName', <any>userName);
        }
        if (companyCoCode !== undefined && companyCoCode !== null) {
            queryParameters = queryParameters.set('companyCoCode', <any>companyCoCode);
        }
        if (statementYear !== undefined && statementYear !== null) {
            queryParameters = queryParameters.set('statementYear', <any>statementYear);
        }
        if (submissionFilingPeriod !== undefined && submissionFilingPeriod !== null) {
            queryParameters = queryParameters.set('submissionFilingPeriod', <any>submissionFilingPeriod);
        }
        if (isKey !== undefined && isKey !== null) {
            queryParameters = queryParameters.set('isKey', <any>isKey);
        }
        if (statementTypeDescription !== undefined && statementTypeDescription !== null) {
            queryParameters = queryParameters.set('statementTypeDescription', <any>statementTypeDescription);
        }
        if (start !== undefined && start !== null) {
            queryParameters = queryParameters.set('start', <any>start);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }
        if (sortOrder !== undefined && sortOrder !== null) {
            queryParameters = queryParameters.set('sortOrder', <any>sortOrder);
        }

        let headers = this.defaultHeaders;

        // authentication (OAuth2) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrdersSearchResult>(`${this.basePath}/orders/orderSearch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
