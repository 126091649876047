/*!
 * Copyright 2024 National Association of Insurance Commissioners
 */
import { isDevMode, Injectable  } from '@angular/core';

@Injectable( {
  providedIn: 'root',
} )
export class NcuiDevModeService {
  isDevMode() {
    return isDevMode();
  }
}
