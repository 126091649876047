/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { CompaniesStatementsOrderBy } from '../../../model/company/companiesStatementsOrderBy';
import { CompaniesStatementsSearchResult } from '../../../model/company/companiesStatementsSearchResult';
import { CompaniesStatementsSearchResults } from '../../../model/company/companiesStatementsSearchResults';
import { CompanyData } from '../../../model/company/companyData';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component( {
  selector: 'app-company-search-results',
  templateUrl: './company-search-results.component.html',
  styleUrls: [ './company-search-results.component.scss' ],
} )
export class CompanySearchResultsComponent implements OnChanges {
  @Output() selectedCompany: EventEmitter<CompanyData> = new EventEmitter<CompanyData>();
  @Output() newPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() newSort: EventEmitter<CompaniesStatementsOrderBy> = new EventEmitter<CompaniesStatementsOrderBy>();

  @Input() companiesStatementsSearchResults: CompaniesStatementsSearchResults;

  currentPage = 1;
  totalItems = 0;
  maxSize = 5;
  params: any = {};
  companyResults: CompaniesStatementsSearchResult[] = [];
  itemsMatched: number;

  dataLoaded = false;

  itemsPerPage = 10;
  public companiesStatementsOrderBy = CompaniesStatementsOrderBy; // For Scope
  sortField: CompaniesStatementsOrderBy;
  totalResult: number;

  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;

  constructor() {
  }

  ngOnChanges( changes: SimpleChanges ) {
    if ( changes[ 'companiesStatementsSearchResults' ] ) {
      this.processData();
    }
  }

  calcTotalPages(): number {
    return Math.ceil( this.itemsMatched / this.itemsPerPage );
  }

  calcStartNumber(): number {
    return ( this.itemsPerPage * ( this.currentPage - 1 ) ) + 1;
  }

  calcEndNumber(): number {
    return this.currentPage === this.calcTotalPages() ? this.itemsMatched : this.calcStartNumber() + ( this.itemsPerPage - 1 );
  }

  processData(): void {
    this.currentPage = this.companiesStatementsSearchResults ? this.companiesStatementsSearchResults.pageSearchResults.startItemRequested / this.itemsPerPage + 1 : 1;
    this.companyResults = this.companiesStatementsSearchResults ? this.companiesStatementsSearchResults.companiesStatements : undefined;
    this.sortField = this.companiesStatementsSearchResults ? this.companiesStatementsSearchResults.orderedBy : undefined;
    this.totalItems = this.companiesStatementsSearchResults ? this.companiesStatementsSearchResults.pageSearchResults.numItemsMatched : 0;
    this.itemsMatched = this.companiesStatementsSearchResults.pageSearchResults.numItemsMatched;
    this.dataLoaded = true;
  }

  showCompanyDataDetail( company: CompanyData ) {
    this.selectedCompany.emit( company );
  }

  sort( newSortField: CompaniesStatementsOrderBy ) {
    this.sortField = newSortField;
    this.newSort.emit( this.sortField );
  }

  newPageEvent( event: PageChangedEvent ) {
    this.newPage.emit( event.page );
  }
}
