/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { OktaAuthOptions } from '@okta/okta-auth-js';

export const environment = {
  production: true,
  envName: 'prod',
  redirectBaseUrl: 'https://insdata.naic.org',
  apiUrl: 'https://insdata-services.naic.org',
  userSignUpUrl: 'https://sspr.common.naic.org/sspr/public/newuser?forwardURL=',
  sessionTimeOut: 1800000,
  okta: {
    issuer: 'https://authenticate.naic.org/oauth2/default',
    clientId: '0oapwrxxecksUjafH4x7',
    redirectUri: 'https://insdata.naic.org/login/callback',
    scopes: [ 'openid', 'profile', 'email', 'NAICProfile' ],
    pkce: true,
    storageManager: {
      token: {
        storageKey: 'okta-token-storage',
        storageType: 'sessionStorage',
      },
      cache: {
        storageKey: 'okta-cache-storage',
        storageType: 'sessionStorage',
      },
    },
  } as OktaAuthOptions,
};
