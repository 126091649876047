<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div id="account-page" class="row">
  <div class="col-12">

    <ng-container *ngIf="loading; else notLoadingAccount">
      <div class="row">
        <div class="col-12">
          <span class="text-center">
            <strong>Loading Account...</strong>&nbsp;
            <!-- FIXME:: Convert to new angular fontawesome -->
            <span class="fa fa-spinner fa-spin fa-lg"></span>
          </span>
        </div>
      </div>
    </ng-container>
    <ng-template #notLoadingAccount>
      <div class="row mb-3 justify-content-center">
        <div class="col-4">
          <h3>Account Profile</h3>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <form (submit)="updateAccount()">
            <div *ngFor="let field of _.keys(accountForm)" class="row justify-content-center mb-3">
              <label class="sr-only" [id]="'tst-account-update-label-' + field">{{ displayField(field) }}</label>
              <div class="col-4">
                <input [(ngModel)]="accountForm[field].text"
                       type="text"
                       [name]="'account-update-' + field"
                       [id]="'account-update-' + field"
                       class="form-control"
                       [placeholder]="getPlaceholder(field)"
                       [disabled]="accountForm[field].disabled">
              </div>
            </div>
            <div class="row mb-3">
              <div class="col text-center">
                <button type="submit" class="btn btn-primary me-2"
                        id="tst-account-update-save-btn"
                        [disabled]="!formIsValid()">
                  Update Account
                </button>
                <button type="button" class="btn btn-secondary" (click)="resetForm()"
                        id="tst-account-update-reset-btn">
                  Reset
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
  </div>
</div>
