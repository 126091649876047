<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<ng-container *ngIf="dataLoaded">
  <div id="company-search-results-header" class="row px-2">
    <div class="col-2"><h3>Results</h3></div>
    <div class="col-10">
      <p class="page-indicator float-end font-weight-bold" id="tst-company-search-page-indicator">
        {{ calcStartNumber() }} - {{ calcEndNumber() }} of {{ totalItems }}
      </p>
    </div>
  </div>

  <div id="company-search-results">
    <table id="company-results-table" class="table table-striped table-hover"
           aria-describedby="company search results table">
      <thead>
      <tr>
        <th class="clickable" scope="col"
            (click)="sort(sortField !== companiesStatementsOrderBy.CompanyNameAsc ? companiesStatementsOrderBy.CompanyNameAsc : companiesStatementsOrderBy.CompanyNameDesc)">
          Company Name
          <fa-icon [icon]="faCaretUp"
                   *ngIf="sortField === companiesStatementsOrderBy.CompanyNameAsc"></fa-icon>
          <fa-icon [icon]="faCaretDown"
                   *ngIf="sortField === companiesStatementsOrderBy.CompanyNameDesc"></fa-icon>
        </th>
        <th class="clickable" scope="col"
            (click)="sort(sortField !== companiesStatementsOrderBy.CompanyCoCodeAsc ? companiesStatementsOrderBy.CompanyCoCodeAsc : companiesStatementsOrderBy.CompanyCoCodeDesc)">
          CoCode
          <fa-icon [icon]="faCaretUp"
                   *ngIf="sortField === companiesStatementsOrderBy.CompanyCoCodeAsc"></fa-icon>
          <fa-icon [icon]="faCaretDown"
                   *ngIf="sortField === companiesStatementsOrderBy.CompanyCoCodeDesc"></fa-icon>
        </th>
        <th scope="col">Statement Types</th>
        <th class="clickable" scope="col"
            (click)="sort(sortField !== companiesStatementsOrderBy.StateOfDomicileAsc ? companiesStatementsOrderBy.StateOfDomicileAsc : companiesStatementsOrderBy.StateOfDomicileDesc)">
          State of Domicile
          <fa-icon [icon]="faCaretUp"
                   *ngIf="sortField === companiesStatementsOrderBy.StateOfDomicileAsc"></fa-icon>
          <fa-icon [icon]="faCaretDown"
                   *ngIf="sortField === companiesStatementsOrderBy.StateOfDomicileAsc"></fa-icon>
        </th>
        <th scope="col">Address</th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let companyResult of companyResults"
          (click)="showCompanyDataDetail(companyResult.companyData)"
          class="clickable">
        <td>{{ companyResult.companyData.companyName }}</td>
        <td>{{ companyResult.companyData.companyCoCode }}</td>
        <td>
          <div *ngFor="let compStatementType of companyResult.companyData.statementTypes">
            {{ compStatementType.value }} <br/>
          </div>
        </td>
        <td>{{ companyResult.companyData.stateOfDomicile }}</td>
        <td>
          {{ companyResult.companyData.streetAddress1 }}<br/>
          <ng-container *ngIf="companyResult.companyData.streetAddress2?.length > 0">
            {{ companyResult.companyData.streetAddress2 }}<br/>
          </ng-container>
          {{ companyResult.companyData.city }}
          , {{ companyResult.companyData.state }} {{ companyResult.companyData.postalCode }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <pagination
        [totalItems]="totalItems"
        [(ngModel)]="currentPage"
        [itemsPerPage]="itemsPerPage"
        [maxSize]="maxSize"
        [boundaryLinks]="true"
        (pageChanged)="newPageEvent($event)"></pagination>
    </div>
  </div>
</ng-container>



