/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { BulkOrderManagerGuard } from './guards/bulk-order-manager-guard';
import { CocodeGuard } from './guards/cocode-guard';
import { UserGuard } from './guards/user-guard';
import { AccountComponent } from './public/account/account.component';
import { AgreementComponent } from './public/agreement/agreement.component';
import { BulkOrderManagerComponent } from './public/bulk-order-manager/bulk-order-manager.component';
import { CocodeUploadComponent } from './public/cocode-upload/cocode-upload.component';
import { HomeComponent } from './public/home/home.component';
import { ShoppingCartComponent } from './public/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './secured/checkout/checkout.component';
import { ViewOrdersComponent } from './secured/view-orders/view-orders.component';
import { InsdataCheckoutResolver } from './service/insdata-checkout-resolver';
import { InsdataCocodeYearResolver } from './service/insdata-cocode-year-resolver';
import { InsdataStatementTypeResolver } from './service/insdata-statement-type-resolver';
import { InsdataStatesResolver } from './service/insdata-states-resolver.service';
import { InsdataUserAgreementResolver } from './service/insdata-user-agreement-resolver';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'login/callback', component: OktaCallbackComponent },
  {
    path: 'home', component: HomeComponent, canActivateChild: [ OktaAuthGuard, UserGuard ], children: [
      { path: '', redirectTo: 'agreement', pathMatch: 'full' },
      {
        path: 'agreement', component: AgreementComponent,
        resolve: {
          endUserAgreement: InsdataUserAgreementResolver,
        },
      },
      { path: 'account', component: AccountComponent },
      {
        path: 'companySearch', loadChildren: () => import( './public/company/company.module' ).then( ( m ) => m.CompanyModule ),
        resolve: {
          statementTypes: InsdataStatementTypeResolver,
          states: InsdataStatesResolver,
        },
      },
      { path: 'shoppingCart', component: ShoppingCartComponent },
      {
        path: 'checkout', component: CheckoutComponent,
        resolve: {
          cart: InsdataCheckoutResolver,
        },
      },
      { path: 'viewOrders', component: ViewOrdersComponent },
      {
        path: 'cocodeUpload', component: CocodeUploadComponent, canActivate: [ CocodeGuard ],
        resolve: {
          orderYears: InsdataCocodeYearResolver,
        },
      },
      { path: 'bulkOrderManager', component: BulkOrderManagerComponent, canActivate: [ BulkOrderManagerGuard ] },
    ],
  },
];

@NgModule( {
  imports: [ RouterModule.forRoot( routes ) ],
  exports: [ RouterModule ],
  providers: [
    InsdataUserAgreementResolver,
    InsdataCheckoutResolver,
    InsdataStatementTypeResolver,
    InsdataStatesResolver,
    UserGuard,
    CocodeGuard,
    BulkOrderManagerGuard,
    InsdataCocodeYearResolver,
  ],
} )
export class AppRoutingModule {
}
