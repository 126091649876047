<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<!-- Company Search -->
<div id="company-search-page" class="row">

  <section class="col-md-4 company-search-form">
    <h3 id="companySearchHeader" class="mt-2">Company Search</h3>
    <!-- FIXME:: Make this form a reactive form -->
    <!-- FIXME:: Make this ngSubmit instead of (submit) -->
    <!-- FIXME:: remove (keydown) from this form -->
    <form (submit)="searchCompanies()" (keydown)="formOnKeyDownFunction($event)">
      <div class="mb-3">
        <label for="companyName" class="form-label">Company Name</label>
        <input type="text"
               required
               class="form-control input-sm"
               id="companyName"
               name="companyName"
               [(ngModel)]="companySearchCriteria.companyName">
      </div>
      <div class="mb-3">
        <label for="companyCode" class="form-label">Company Code</label>
        <input type="text"
               min="0"
               maxlength="5"
               required
               (keydown)="onlyNumbersAllowed($event)"
               class="form-control input-sm no-spin"
               id="companyCode"
               [(ngModel)]="companySearchCriteria.companyCoCode"
               name="companyCode"
               #companyCode="ngModel">
      </div>
      <div id="statementTypes" *ngIf="allStatementTypes" class="mb-3">
        <span class="form-label">Statement Type</span>
        <div class="mb-3 form-check">
          <input type="radio"
                 class="form-check-input"
                 id="ALL"
                 name="statementType"
                 [value]="undefined"
                 [(ngModel)]="companySearchCriteria.statementType"/>
          <label for="ALL" class="form-check-label ps-1">All</label>
          <div *ngFor="let statementType of allStatementTypes?.statementTypes">
            <input type="radio"
                   class="form-check-input"
                   id="{{statementType.value}}"
                   name="statementType"
                   [value]="statementType"
                   [(ngModel)]="companySearchCriteria.statementType"/>
            <label for="{{statementType.value}}" class="form-check-label ps-1">{{ statementType.value }}</label>
          </div>
        </div>
      </div>

      <div id="stateOfDomicile" *ngIf="allStates" class="mb-3">
        <label class="form-label">State of Domicile</label>
        <select class="form-select" [(ngModel)]="companySearchCriteria.stateOfDomicile" name="selectedState">
          <option [ngValue]="undefined">All States</option>
          <option *ngFor="let state of allStates?.states" value="{{state.code}}">{{ state.description }}</option>
        </select>
      </div>

      <div id="licenseState" *ngIf="allStates" class="mb-3">
        <label class="form-label">Licensed in State</label>
        <select class="form-select" [(ngModel)]="companySearchCriteria.licensedInState"
                name="selectedLicensedState">
          <option [ngValue]="undefined">All States</option>
          <option *ngFor="let state of allStates?.states" value="{{state.code}}">{{ state.description }}</option>
        </select>
      </div>

      <div class="row">
        <div class="col-12 text-center">
          <!-- FIXME:: Make this a button instead of an input -->
          <input type="submit" value="Search" class="btn btn-primary me-2" id="tst-company-search-save-btn"
                 [disabled]="!formIsValid()">
          <!-- FIXME:: Make this a button instead of an input -->
          <input type="button" value="Reset" class="btn btn-secondary" (click)="resetForm()"
                 id="tst-company-search-reset-btn">
        </div>
      </div>

    </form>
  </section>

  <section class="col-md-8 mt-2 mt-md-0">
    <!-- Instructions when no search made -->
    <ng-container *ngIf="!companiesStatementsResults">
      <div class="row">
        <div class="col-12 text-center">
          <p><strong>Enter at least one criterion to begin your search.</strong></p>
        </div>
      </div>
    </ng-container>
    <!-- No Results -->
    <ng-container *ngIf="companiesStatementsResults?.companiesStatements.length === 0">
      <div class="row">
        <div class="col-12 text-center">
          <p><strong>Your search yielded no results.</strong></p>
        </div>
      </div>
    </ng-container>
    <!-- Results -->
    <ng-container *ngIf="companiesStatementsResults?.companiesStatements.length > 0 && !selectedCompany">
      <app-company-search-results
        (selectedCompany)="onSelectedCompany($event)"
        (newPage)="onNewPage($event)"
        (newSort)="onNewSort($event)"
        [companiesStatementsSearchResults]="companiesStatementsResults"></app-company-search-results>
    </ng-container>
    <!-- Company Details -->
    <ng-container *ngIf="selectedCompany">
      <app-company-search-detail
        [company]="selectedCompany"
        (closeDetail)="onCloseDetail()"></app-company-search-detail>
    </ng-container>
  </section>

</div>
