/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BillingResponse } from '../../model/billing/billingResponse';
import { NoChargeBillingRemittance } from '../../model/billing/noChargeBillingRemittance';
import { Cart } from '../../model/cart/cart';
import { CartItem } from '../../model/cart/cartItem';
import { OrderDetail } from '../../model/order/orderDetail';
import { InsdataBillingService } from '../../service/insdata-billing.service';
import { InsdataCustomerService } from '../../service/insdata-customer.service';
import { InsdataOrdersService } from '../../service/insdata-orders.service';
import { InsdataShoppingCartService } from '../../service/insdata-shopping-cart.service';
import { StandardModalComponent } from '../../shared/modals/standard-modal/standard-modal.component';

@Component( {
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: [ './shopping-cart.component.scss' ],
} )
export class ShoppingCartComponent implements OnInit, OnDestroy {
  @ViewChild( 'orderConfirmationModal', { static: false } ) orderConfirmationModal: ModalDirective;
  @ViewChild( 'submittingOrderModal', { static: false } ) submittingOrderModal: ModalDirective;
  @ViewChild( 'submittingPaymentErrorModal', { static: false } ) submittingPaymentErrorModal: ModalDirective;
  userId: string;
  cart: Cart;
  subtotalPrice = 0;
  truncatedOrderIdLength = 6;

  billingResponseForDoNotCharge: BillingResponse;
  orderConfirmationDetailForDoNotCharge: OrderDetail;

  clearCartModal: BsModalRef;

  subscriptions: Subscription[] = [];

  private readonly ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly shoppingCartService: InsdataShoppingCartService,
    private readonly insdataCustomerService: InsdataCustomerService,
    private readonly insdataBillingService: InsdataBillingService,
    private readonly insdataOrderService: InsdataOrdersService,
    private readonly router: Router,
    private modalService: BsModalService,
  ) {
  }

  ngOnInit() {
    this.shoppingCartService.refreshCart().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( cart ) => {
      this.cart = cart;
      this.calculateSubTotalPrice();
    } );

    this.insdataCustomerService.getCurrentUser().pipe( take( 1 ) ).subscribe( ( data ) => {
      this.userId = data.userId;
    } );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscriptions.forEach( ( subscription: Subscription ) => {
      subscription.unsubscribe();
    } );
  }

  removeFromCart( cartItem: CartItem ) {
    this.shoppingCartService.removeCartItemFromCart( cartItem ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( cart ) => {
      this.cart = cart;
      this.calculateSubTotalPrice();
    } );

  }

  clearCart() {
    this.shoppingCartService.clearCart().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( cart ) => {
      this.cart = cart;
      this.calculateSubTotalPrice();
    } );
  }

  checkout() {
    this.insdataCustomerService.isCurrentUserDoNotCharge().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( result ) => {
      if ( result ) {
        this.submittingOrderModal.show();
        const doNotChargeRemittance: NoChargeBillingRemittance = {
          remittanceType: 'NoChargeBillingRemittance',
        };
        this.insdataBillingService.payForCart( doNotChargeRemittance, this.userId ).pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( billingResponse: BillingResponse ) => {
          this.billingResponseForDoNotCharge = billingResponse;
          this.insdataOrderService.fetchOrderDetail( this.billingResponseForDoNotCharge.orderId )
            .pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( orderDetail: OrderDetail ) => {
            this.orderConfirmationDetailForDoNotCharge = orderDetail;
            this.submittingOrderModal.hide();
            this.orderConfirmationModal.show();
          } );
        }, () => {
          this.submittingOrderModal.hide();
          this.submittingPaymentErrorModal.show();
        } );
      } else {
        this.router.navigate( [ 'home/checkout' ] );
      }
    } );

  }

  calculateSubTotalPrice() {
    this.subtotalPrice = 0;
    if ( this.cart.items ) {
      for ( const item of this.cart.items ) {
        this.subtotalPrice += item.product.priceUsd;
      }
    }
  }

  continue() {
    this.shoppingCartService.refreshCart().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( () => {
      this.router.navigate( [ 'home/viewOrders' ] );
    } );
  }

  showClearCartModal(): void {
    const initialState: ModalOptions = {
      initialState: {
        title: 'Clear Cart',
        body: 'Are you sure you want to clear your cart?',
        primaryButtonText: 'Clear Cart',
        primaryButtonClass: 'btn-primary',
        secondaryButtonText: 'Cancel',
        secondaryButtonClass: 'btn-secondary'
      }
    };
    this.clearCartModal = this.modalService.show( StandardModalComponent, initialState );

    this.subscriptions.push( this.clearCartModal?.onHide.subscribe( () => {
      if ( this.clearCartModal?.content.primaryAction ) {
        this.clearCart();
      }
    } ) );
  }
}
