/*
 * Copyright 2025 National Association of Insurance Commissioners
 */


import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import $ from 'jquery';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BillingResponse } from '../../model/billing/billingResponse';
import { CreditCardBillingRemittance } from '../../model/billing/creditCardBillingRemittance';
import { StripeToken } from '../../model/billing/stripeToken';
import { Cart } from '../../model/cart/cart';
import { OrderDetail } from '../../model/order/orderDetail';
import { InsdataBillingService } from '../../service/insdata-billing.service';
import { InsdataCustomerService } from '../../service/insdata-customer.service';
import { InsdataMessageService } from '../../service/insdata-message.service';
import { InsdataOrdersService } from '../../service/insdata-orders.service';
import { InsdataShoppingCartService } from '../../service/insdata-shopping-cart.service';

@Component( {
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: [ './checkout.component.scss' ],
} )
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild( 'orderConfirmationModal', { static: false } ) orderConfirmationModal: ModalDirective;
  @ViewChild( 'submittingPaymentModal', { static: false } ) submittingPaymentModal: ModalDirective;
  @ViewChild( 'submittingPaymentErrorModal', { static: false } ) submittingPaymentErrorModal: ModalDirective;
  numberOfItems = 0;
  totalPrice = 0;
  orderId: string;
  billingResponse: BillingResponse;
  orderConfirmationDetail: OrderDetail;
  truncatedOrderIdLength = 6;
  userId: any;
  paymentIntent: StripeToken;
  elements: any;
  card: any;
  stripe: any;
  loading = false;
  private readonly ngUnsubscribe: Subject<void> = new Subject();

  constructor( private readonly shoppingCartService: InsdataShoppingCartService,
               private readonly _location: Location,
               private readonly router: Router,
               private readonly route: ActivatedRoute,
               private readonly insDataBillingService: InsdataBillingService,
               private readonly insDataOrderService: InsdataOrdersService,
               private readonly insdataCustomerService: InsdataCustomerService,
               private readonly insdataMessageService: InsdataMessageService ) {
  }


  ngOnInit() {
    this.route.data.pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( data: { cart: Cart } ) => {
      if ( data.cart && data.cart.items ) {
        this.numberOfItems = data.cart.items.length;
        for ( const item of data.cart.items ) {
          this.totalPrice += item.product.priceUsd;
        }
      }
      this.loading = true;
    } );
    // Get the current User Id from customer service and pass it on to billing service.
    this.insdataCustomerService.getCurrentUser().pipe( take( 1 ) ).subscribe( ( data ) => {
      this.userId = data.userId;
    } );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if ( this.paymentIntent ) {
      this.insDataBillingService.deletePaymentIntent( this.paymentIntent.paymentIntentId, this.userId ).subscribe( () => {
        // Nothing to do.
      }, () => {
        console.error( 'Something went wrong cancelling your payment intent' );
      } );
    }
  }

  cancelPayment() {
    this.insDataBillingService.deletePaymentIntent( this.paymentIntent.paymentIntentId, this.userId ).subscribe( () => {
      this._location.back();
    }, () => {
      console.error( 'Something went wrong cancelling your payment intent.' );
    } );
  }

  completeOrder( event ) {
    event.preventDefault();
    this.payWithCard( this.stripe, this.card, this.paymentIntent.clientSecretToken );
  }

  continue() {
    this.shoppingCartService.refreshCart().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( () => {
      this.router.navigate( [ 'home/viewOrders' ] );
    } );
  }

  ngAfterViewInit() {
    // Get a payment intent with the passed in token.
    this.insDataBillingService.generatePaymentIntent( this.userId ).pipe( take( 1 ) ).subscribe( ( token ) => {
      this.loading = false;
      // If a valid paymentIntent is obtained, show the card elements on the UI.
      if ( token ) {
        this.paymentIntent = token;
        this.stripe = Stripe( this.paymentIntent.publicAccessKey );
        this.elements = this.stripe.elements();
        this.card = this.stripe.elements().create( 'card' );
        this.card.mount( '#card-element' );
      }

      // Upon changing any values on the card, stripe automatically displays error message because of this code.
      this.card.on( 'change', function ( event ) {
        document.querySelector( '#card-error' ).textContent = event.error ? event.error.message : '';
        // TODO:: Why are we using jQuery for this? Angular is capable of this type of functionality.
        if ( event.error ) {
          $( '#submit' ).prop( 'disabled', true );
        } else {
          $( '#submit' ).prop( 'disabled', false );
        }
      } );
    }, () => {
      console.error( 'Something went wrong fetching a Payment Intent.' );
    } );
  }

  // Custom pay method where we write all the logic of what must happen like show modal before and after, show error messages etc.
  payWithCard( stripe, card, clientSecret ) {
    this.submittingPaymentModal.show();
    this.stripe.confirmCardPayment( clientSecret, {
      payment_method: {
        card: card,
      },
    } ).then( ( result ) => {
      if ( !result.error ) {
        const creditCardRemittance: CreditCardBillingRemittance = {
          paymentIntentId: this.paymentIntent.paymentIntentId,
          remittanceType: 'CreditCardBillingRemittance',
        };
        this.insDataBillingService.payForCart( creditCardRemittance, this.userId ).pipe( take( 1 ) ).subscribe( ( data: BillingResponse ) => {
          this.billingResponse = data;
          this.insDataOrderService.fetchOrderDetail( data.orderId ).pipe( take( 1 ) ).subscribe( ( order ) => {
            this.orderConfirmationDetail = {
              ...order,
              // @ts-ignore
              orderData: { ...order.orderData, purchaseDate: new Date( ( order.orderData.purchaseDate.getMilliseconds() ) + 21600000 ) },
            };
          }, () => {
            this.insdataMessageService.showErrorMessage( 'Something went wrong fetching order details. Please check your orders page for this order details.', false, 5000 );
          } );
        }, () => {
          this.insdataMessageService.showErrorMessage( 'Something went wrong saving your order to database.', false, 5000 );
        }, () => {
          this.paymentIntent = null;
          this.submittingPaymentModal.hide();
          this.orderConfirmationModal.show();
        } );
      } else {
        this.submittingPaymentModal.hide();
        this.insdataMessageService.showErrorMessage( result.error.message, false, 10000 );
      }
    } );
  }
}
