<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div bsModal #loadingSpinnerModal="bs-modal" class="modal" id="loadingSpinnerModal" tabindex="-1" role="dialog"
     [config]="{backdrop: 'static', keyboard: false}">
  <div class="modal-dialog loading-modal" role="document">
    <div class="modal-content loading-modal-content">
      <!-- FIXME:: Convert to new angular fontawesome -->
      <span class="fa fa-spinner fa-spin fa-3x"></span>
    </div>
  </div>
</div>
