/*!
 * Copyright 2024 National Association of Insurance Commissioners
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import {ApiAddress} from '../model/ncui-interfaces';
import { NcuiDevModeService } from './ncui-dev-mode.service';

@Injectable( {
  providedIn: 'root',
} )
export class NcuiAddressService {
  addressesUrl = '/naic-common-ui-service/api/addresses';
  testApiUrl = 'http://jsonplaceholder.typicode.com';

  constructor(
    private http: HttpClient,
    private devModeService: NcuiDevModeService
  ) { }

  getAddresses(): Observable<ApiAddress[]> {
    const hardCodedAddresses = [
      {
        companyName: 'NAIC Central Office',
        companyDesignation: null,
        street1: '1100 Walnut Street',
        street2: 'Suite 1000',
        city: 'Kansas City',
        state: 'MO',
        zip: '64106-2197',
        phone: '8167838500',
        fax: '8167838500',
        switchBoardHours: '8 a.m. to 4 p.m. (CT)',
        switchBoardDays: 'Monday - Friday',
        email: 'help@naic.org',
        brand: 'naic',
      },
      {
        companyName: 'NAIC Executive Office',
        companyDesignation: 'Government Relations Contacts',
        street1: '444 North Capitol Street NW',
        street2: 'Suite 700',
        city: 'Washington',
        state: 'DC',
        zip: '20001',
        phone: '8167838500',
        fax: '8167838500',
        switchBoardHours: '8 a.m. to 4 p.m. (CT)',
        switchBoardDays: 'Monday - Friday',
        email: 'help@naic.org',
        brand: 'naic',
      },
      {
        companyName: 'Capital Markets & Investment Analysis Office',
        companyDesignation: null,
        street1: 'One New York Plaza',
        street2: 'Suite 4210',
        city: 'New York',
        state: 'NY',
        zip: '10004',
        phone: '8167838500',
        fax: '8167838500',
        switchBoardHours: '8 a.m. to 4 p.m. (CT)',
        switchBoardDays: 'Monday - Friday',
        email: 'help@naic.org',
        brand: 'naic',
      },
    ];

    if ( this.devModeService.isDevMode() ) {  //  Local
      return of( hardCodedAddresses );
    }

    return this.http.get<ApiAddress[]>( this.addressesUrl );  //   Https://eapps-dvlp.naic.org/naic-common-ui-service/api/addresses
  }
}
