<!--
  ~ Copyright 2025 National Association of Insurance Commissioners
  -->

<div class="row justify-content-center mt-3" *ngIf="!fetchingCocodeLists">
  <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
    <div class="alert alert-warning" role="alert" *ngIf="orderYears?.length === 0" id="noContractsAvailableText">
      Please contact Admin to assign cocode download limit.
    </div>

    <div *ngIf="onUploadCocodePage||onCreateBulkOrderPage" aria-label="breadcrumb">
      <!-- FIXME:: Move inline style to style sheet -->
      <ol class="breadcrumb" style="background-color: white; padding: 0.75rem 0">
        <!-- FIXME:: Move inline style to style sheet -->
        <li class="breadcrumb-item">
          <a (click)="goBackToCocodeListManager()"
             style="color:#2c6ba0"
             id="bc_link_cocodeListManager">
            Cocode List Manager
          </a>
        </li>
        <li class="breadcrumb-item active"
            aria-current="page"
            *ngIf="onUploadCocodePage">
          Cocode List Upload
        </li>
        <li class="breadcrumb-item active"
            aria-current="page"
            *ngIf="onCreateBulkOrderPage">
          Create Bulk Order
        </li>
      </ol>
    </div>

    <div *ngIf="!onUploadCocodePage && !onCreateBulkOrderPage" id="cocodeListHistory">

      <div class="row">
        <div class="col-12">
          <h2>Cocode List Manager
            <span *ngIf="orderYears?.length > 0">
              <button class="btn btn-outline-danger float-end" (click)="confirmDeletionOfCocodes()"
                      *ngIf="!loadingSingleListItem && availableYears?.length>0">Remove All Unused Cocodes</button>
              <button class="btn btn-secondary float-end me-2" (click)="onUploadCocodePage = true"
                      id="uploadCocodesBtn">Upload Cocodes
              </button>
              <button class="btn btn-primary float-end me-2" *ngIf="!loadingSingleListItem && availableYears.length>0"
                      (click)="showCreateBulkOrderPage()" id="createBulkOrderBtn">Create Bulk Order</button>
            </span>
          </h2>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!onUploadCocodePage && !onCreateBulkOrderPage" id="uploadedCocodesListPage">
      <div class="col">
        <div class="cocodeHistory cocodesNotAvailable" *ngIf="availableYears?.length === 0">
          No cocode lists were previously uploaded.
        </div>
        <div class="cocodesAvailable" *ngIf="availableYears?.length > 0">
          <!-- FIXME:: Move inline style to style sheet -->
          <h5 style="white-space: nowrap" class="mt-3">
            View By Order Year&nbsp;
            <span class="ttip">
              <!-- FIXME:: Convert to new angular fontawesome -->
              <span class="fa fa-question-circle" title="" style="color:#1e73be;"></span>
              <span class="ttiptext">Order year starts on March 1st every year.</span>
            </span>
          </h5>
          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
              <select id="availableYear" [class]="'form-control mb-3'" [(ngModel)]="selectedYearToViewHistory"
                      name="yearSelect" (change)="fetchCocodeListByPageNumber( 1 )">
                <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
              </select>
            </div>
          </div>
          <div *ngIf="onViewHistoryPage" id="cocodeUploadHistoryPage">
            <!-- FIXME:: Move inline style to style sheet -->
            <a style="color:#2c6ba0; display: inline" (click)="exitOnViewHistoryPage()">
              <!-- FIXME:: Convert to new angular fontawesome -->
              <span class="fa fa-arrow-left"></span>&nbsp;{{ returnToListViewMessage }}
            </a><br/><br/>
            <div *ngFor="let cocodeHistItem of cocodeListForSelectedYear.cocodeListHistories">
              <div class="card h-25">
                <div class="card-body" id="cocodeUploadInfoBody">
                  <h4 class="card-title" id="cocodeUploadInfoHeader">{{ cocodeHistItem.numCoCodesAffected }}
                    Cocodes <span *ngIf="cocodeHistItem.action==='Add'">Added</span>
                    <span *ngIf="cocodeHistItem.action==='Delete'">Removed</span>
                  </h4>
                  <p><strong><span *ngIf="cocodeHistItem.action==='Add'">Added</span><span
                    *ngIf="cocodeHistItem.action==='Delete'">Removed</span>
                    on</strong> <span
                    id="cocodeUploadDtInfo">{{ cocodeHistItem.actionDateTime * 1000|date:'short' }}</span></p>
                </div>
                <div class="card-body" *ngIf="loadingSingleListItem">
                  <p>Loading Cocode Upload History for {{ selectedYearToViewHistory }}...
                    <!-- FIXME:: Convert to new angular fontawesome -->
                    <span class="fa fa-spinner fa-spin fa-lg"></span></p>
                </div>
              </div>
            </div>
            <div class="mt-3 d-flex justify-content-center">
              <pagination
                [totalItems]="calcTotalPagesForViewHistory()"
                [(ngModel)]="currentPageViewHistory"
                [itemsPerPage]="rowsPerPage"
                [maxSize]="maxSize"
                [boundaryLinks]="true"
                (pageChanged)="navigateFromPaginationForViewHistory($event)"></pagination>
            </div>
          </div>
          <div *ngIf="!onViewHistoryPage">
            <div class="row mb-3">
              <div class="col">
                <div class="d-flex">
                  <div class="">
                    <!-- FIXME:: Move inline style to style sheet -->
                    <h4 style="display: inline"> {{ selectedYearToViewHistory }} Cocode List </h4>
                    <!-- FIXME:: Move inline style to style sheet -->
                    <a style="color:#2c6ba0; display: inline"
                       id="ExportCocodesLink" (click)="downloadCocodeList()">
                      <!-- FIXME:: Convert to new angular fontawesome -->
                      <span class="fa fa-download"></span> Download Cocode List
                    </a>
                    <span class="ttip">
                      <!-- FIXME:: Convert to new angular fontawesome -->
                      <span class="fa fa-question-circle"
                            title=""
                            style="color:#1e73be;"
                            (click)="showSpecialUserHelpModal(specialUserHelpModal)">
                      </span>
                    </span>
                  </div>
                  <div class="ms-auto">
                    <!-- FIXME:: Move inline style to style sheet -->
                    <a style="color:#2c6ba0; display: inline" (click)="showViewHistoryPage()" id="viewHistoryBtn">View
                      History</a>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="cocodeListForSelectedYear">
              <!-- FIXME:: Move inline style to style sheet -->
              <h5 style="white-space: nowrap" class="mt-3">
                <div class="row">
                  <div class="col-xl-9">Show Status:
                    <button class="btn btn-outline-secondary me-1" (click)="filterCocodes(filterStatus.Nofiling)"
                            id="noFilingBtn">No Filing
                    </button>
                    <button class="btn btn-outline-secondary me-1" (click)="filterCocodes(filterStatus.Available)"
                            id="availableBtn">Available
                    </button>
                    <button class="btn btn-outline-secondary me-1" (click)="filterCocodes(filterStatus.Locked)"
                            id="lockedBtn">Locked
                    </button>
                  </div>
                  <div class="col-xl-2"
                       *ngIf="cocodeListForSelectedYear.pageSearchResults.numItemsMatched">
                    {{ calcStartNumber() }} - {{ calcEndNumber() }} of {{ totalResult }}
                  </div>
                </div>
              </h5>

              <div class="card h-25">
                <div class="card-body" id="cocodeUploadInfoBody0">
                  <table class="table" aria-describedby="Uploaded cocode Table">
                    <thead class="thead-light">
                    <tr>
                      <th *ngFor="let header of uploadedCocodeHeaders" scope="col">
                        {{ header.display }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let uploadedCocode of uploadedCocodes">
                      <td>{{ uploadedCocode.companyName }}</td>
                      <td>{{ uploadedCocode.cocode }}</td>
                      <td>{{ uploadedCocode.status }}</td>
                      <td>{{ ( uploadedCocode.downloadDate ) ? ( ( uploadedCocode.downloadDate * 1000 )|date:'short' ) : '' }}</td>
                      <td>
                        <button (click)="deleteCocode(uploadedCocode.cocode)" type="button" id="removeCocodesBtn"
                                class="btn btn-sm btn-outline-danger">Remove from {{ selectedYear }} Cocode List
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="mt-3 d-flex justify-content-center">
                <pagination
                  [totalItems]="totalResult"
                  [(ngModel)]="currentPage"
                  [itemsPerPage]="rowsPerPage"
                  [maxSize]="maxSize"
                  [boundaryLinks]="true"
                  (pageChanged)="navigateCocodeFromPagination($event)">
                </pagination>
              </div>
            </div>

            <div *ngIf="uploadStatus==='pending'">
              <p>
                Your upload is being processed and we will send you an email shortly. For further assistance please
                contact service desk.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="onUploadCocodePage" id="cocodeUploadPage" class="row">
      <div class="col">
        <div class="row nav-tabs-row">
          <div class="col">
            <ul class="nav nav-tabs">
              <li class="nav-item" id="csvCriteriaTab" (click)="showCSVCriteriaPage()">
                <a [class]="'nav-link' + ((showTab === 'csvCriteriaTab')? ' active' : '')">Upload CSV</a>
              </li>
              <li class="nav-item" id="stmtTypeCriteriaTab" (click)="showStmtTypeCriteriaPage()">
                <a [class]="'nav-link' + ((showTab === 'stmtTypesCriteriaTab')? ' active' : '')">Statement Type</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row" id="csvCriteriaPage" *ngIf="showTab === 'csvCriteriaTab'">
          <div class="col mb-3">
            <h1 class="mt-3 mb-3">Add by CSV Upload</h1>
            <form action="" (ngSubmit)="addByUploadCSV()" ngNativeValidate>
              <div class="row">
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
                  <!-- FIXME:: Move inline style to style sheet -->
                  <h5 style="white-space: nowrap">Order Year <span style="color: red">*</span> <span
                    class="ttip">
                    <!-- FIXME:: Convert to new angular fontawesome -->
                    <span class="fa fa-question-circle" title="" style="color:#1e73be;"></span>
                      <span class="ttiptext">Order year starts on March 1st every year.</span></span>
                  </h5>
                  <div class="mb-3">
                    <select id="orderYear" [class]="'form-control' + ((errorCode === 1)? ' erroneous' : '')" required
                            [(ngModel)]="selectedYear" name="yearSelect" (change)="clearError()">
                      <option [ngValue]="undefined"></option>
                      <option *ngFor="let year of orderYears" [value]="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-8 col-sm-10">
                  <!-- FIXME:: Move inline style to style sheet -->
                  <h4>Upload File <span style="color: red">*</span></h4>
                  <small>Max file size 2 MB</small><br>
                  <!-- FIXME:: Move inline style to style sheet -->
                  <label class="custom-file mb-2" style="width: 100%;">
                    <input type="file"
                           [class]="'custom-file-input'+ ((errorCode === 2 || errorCode === 3 || errorCode === 4)? ' erroneous' : '')"
                           id="fileUpload" aria-describedby="Upload File"
                           name="fileUpload" (change)="selectFile($event)" value="selectedFileName" accept=".csv"
                           #fileInput>
                    <span class="custom-file-control form-control">{{ selectedFileName }}</span>
                  </label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col">
                  <button class="btn btn-primary" type="submit" id="uploadCocodeSubmitBtn">Upload Cocodes</button>
                  <button class="btn btn-link" (click)="goBackToCocodeListManager()" id="cancelBtn">Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row" id="stmtTypeCriteriaPage" *ngIf="showTab === 'stmtTypesCriteriaTab'">
          <div class="col">
            <h1 class="mt-3 mb-3"> Add by Statement Type</h1>
            <div>
              <form [formGroup]="stmtTypeCriteriaForm" (ngSubmit)="addByStmtType()">
                <div class="row">
                  <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <!-- FIXME:: Move inline style to style sheet -->
                    <h5 style="white-space: nowrap"><label for="orderYearForStmtTypeCriteria">Order Year:</label>
                      <!-- FIXME:: Move inline style to style sheet -->
                      <span style="color: red">*</span>
                    </h5>
                    <select id="orderYearForStmtTypeCriteria"
                            [class]="'form-control' + ((errorCode === 1)? ' erroneous' : '')" required
                            [(ngModel)]="selectedYear" name="yearSelect" (change)="clearError()"
                            formControlName="orderYear">
                      <option [ngValue]="undefined"></option>
                      <option *ngFor="let year of orderYears" [value]="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <!-- FIXME:: Move inline style to style sheet -->
                    <h5><label>Statement Type:</label> <span style="color: red">*</span></h5>
                    <div class="ms-3">
                      <label> <input type="radio" formControlName="statementType" value="F" class="erroneous">
                        <span class="ms-2"> Fraternal - (Only for orders before 2019) </span></label><br/>
                      <label> <input type="radio" formControlName="statementType" value="X"> <span
                        class="ms-2"> Health</span>
                      </label><br/>
                      <label> <input type="radio" formControlName="statementType" value="L"> <span
                        class="ms-2"> Life, Accident, and Health </span></label><br/>
                      <label> <input type="radio" formControlName="statementType" value="P"> <span
                        class="ms-2"> Property and Casualty</span> </label><br/>
                      <label> <input type="radio" formControlName="statementType" value="T"> <span
                        class="ms-2"> Title </span>
                      </label><br/>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <div class="flex-row">
                      <button class="btn btn-primary" type="submit" id="stmtTypeCriteriaSubmitBtn"
                              [disabled]="stmtTypeCriteriaForm.touched && !stmtTypeCriteriaForm.valid">Upload Cocodes
                      </button>
                      <button class="btn btn-link" (click)="goBackToCocodeListManager()"
                              id="stmtTypeCriteriaCancelBtn">Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="onCreateBulkOrderPage && !fetchingOrders" id="createBulkOrderPage" class="row">
      <div class="col">
        <h1 class="mb-3">Create Bulk Order</h1>
        <div id="bulkOrderParameters" *ngIf="!pendingBulkOrderExists">
          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
              <!-- FIXME:: Move inline style to style sheet -->
              <h5 style="white-space: nowrap">Select Order Year <span class="ttip">
                <!-- FIXME:: Convert to new angular fontawesome -->
                <span class="fa fa-question-circle" title="" style="color:#1e73be;"></span>
                <span class="ttiptext">Order year starts on March 1st every year.</span></span>
              </h5>
              <div class="mb-3">
                <select id="orderYearForBulkOrdering" [class]="'form-control'"
                        [(ngModel)]="selectedYearToCreateBulkOrder" name="yearSelectForCreatingBulkOrder"
                        (change)="fetchBulkOrderForYear()">
                  <option *ngFor="let year of availableYears" [value]="year">{{ year }}</option>
                </select>
                <!-- FIXME:: Move inline style to style sheet -->
                <small style="white-space: nowrap">Only years with uploaded CoCode Lists will be available for order
                  creation.</small>
              </div>
              <div class="mb-3">
                <div>
                  <input class="mb-2" type="radio" id="allFiles" value="allFiles"
                         [(ngModel)]="selectedBulkOrderType"/>
                  <label class="role-label" for="allFiles">All Files</label>
                </div>
                <div>
                  <input class="mb-2" type="radio" id="changesSinceLast" value="changesSinceLast"
                         [(ngModel)]="selectedBulkOrderType" [disabled]="disableChangesOnly"/>
                  <label class="role-label" for="changesSinceLast">Changes Only</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <button class="btn btn-primary" type="submit" (click)="createBulkOrderValidation()"
                      id="blkOrderCreationBtn">Create
                Bulk Order
              </button>
            </div>
          </div>
        </div>
        <div class="row" id="bulkOrderHistory" *ngIf="pendingBulkOrderExists">
          <div class="col">
            <!-- FIXME:: Move inline style to style sheet -->
            <p style="line-height: 2em" id="pendingOrderExistText">Your previous order is being processed and we will
              email you when it is ready to
              be downloaded. A new order can only be downloaded after the previous order is completed.</p>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div bsModal #fileUploadingModal="bs-modal" class="modal" id="fileUploadingModal" tabindex="-1" role="dialog"
       [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><strong>Uploading File</strong></h5>
        </div>
        <div class="modal-body">
          <!-- FIXME:: Convert to new angular fontawesome -->
          <span class="fa fa-spinner fa-spin fa-2x padded"></span>
          File uploading is in progress. This could take a moment.<br>
          <div class="alert alert-warning">
            Please do not refresh or leave the page until the file upload is completed.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div bsModal #deletingSingleCocodeModal="bs-modal" class="modal" id="deletingSingleCocodeModal" tabindex="-1"
       role="dialog"
       [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><strong>Deleting Cocode</strong></h5>
        </div>
        <div class="modal-body">
          <!-- FIXME:: Convert to new angular fontawesome -->
          <span class="fa fa-spinner fa-spin fa-2x padded"></span>
          Deleting Cocode.<br>
        </div>
      </div>
    </div>
  </div>


  <div bsModal #bulkOrderProcessingModal="bs-modal" class="modal" id="bulkOrderProcessingModal" tabindex="-1"
       role="dialog"
       [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><strong>Placing Order</strong></h5>
        </div>
        <div class="modal-body">
          <!-- FIXME:: Convert to new angular fontawesome -->
          <span class="fa fa-spinner fa-spin fa-2x padded"></span>
          Your bulk order is being placed. <br><br>
          <div class="alert alert-warning">
            Please do not refresh or leave the page until the order is completely processed.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mt-3" *ngIf="fetchingCocodeLists">
    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12">
      <p>Loading Cocode Upload History...
        <!-- FIXME:: Convert to new angular fontawesome -->
        <span class="fa fa-spinner fa-spin fa-lg"></span></p>
    </div>
  </div>

  <div class="row justify-content-center mt-3" *ngIf="fetchingOrders">
    <div class="col-xl-6 col-lg-8 col-md-12 col-sm-12">
      <p>Loading Bulk Orders Page...
        <!-- FIXME:: Convert to new angular fontawesome -->
        <span class="fa fa-spinner fa-spin fa-lg"></span></p>
    </div>
  </div>

  <div bsModal #cocodeDeleteConfirmModal="bs-modal" class="modal" id="cocodeDeleteConfirmModal" tabindex="-1"
       role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><strong>Remove All Unused Cocodes</strong></h5>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeConfirmModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div *ngIf="pendingBulkOrderExists || listLoadOrOrderInProgress">
            <p id="listLoadingOrOrderInProgressMsg">Cannot perform this action while uploading cocodes or when an order
              is in process. Please try again later.</p>
          </div>

          <div *ngIf="!cocodesAvailableToDelete">
            <p id="cocodesUnavailableMsg">There are no cocodes in your list that can be removed for
              the {{ selectedYearToViewHistory }} Order year.</p>
          </div>

          <div *ngIf="cocodesAvailableToDelete && (!pendingBulkOrderExists && !listLoadOrOrderInProgress)"
               id="happyPathMsg">
            <p>Are you sure you want to remove {{ unusedCocodeCount }} unused cocodes with the below statuses for the
              order year
              '{{ selectedYearToViewHistory }}'?</p>
            <!-- FIXME:: Move inline style to style sheet -->
            <ul style="list-style-type: disc; padding-left: -5px">
              <li>{{ availableStatus }}</li>
              <li>{{ noFilingStatus }}</li>
              <li>{{ pendingStatus }}</li>
            </ul>
            <strong>Note:</strong> If unsure, cancel this operation and download cocode list to check the status of the
            cocodes.
          </div>
        </div>
        <div class="modal-footer">
          <div class="align-middle">
            <a class="text-primary me-2" title="Close Confirmation Modal" (click)="closeConfirmModal()">Cancel</a>
            <span *ngIf="cocodesAvailableToDelete && (!pendingBulkOrderExists && !listLoadOrOrderInProgress)">
              <!-- FIXME:: Move inline style to style sheet -->
              <button class="btn btn-sm btn-primary" (click)="deleteCocodes()" *ngIf="!isDeletingCocodes"
                      style="width: 250px;" id="finalDeleteBtn">Yes, Remove All Unused Cocodes </button>
          </span>
            <!-- FIXME:: Move inline style to style sheet -->
            <button class="btn btn-sm btn-primary " *ngIf="isDeletingCocodes" style="width: 250px;">
              <!-- FIXME:: Convert to new angular fontawesome -->
              <span class="fa fa-spinner fa-spin fa-lg"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #specialUserHelpModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Cocode List Help</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideSpecialUserHelpModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="statusList"></ng-container>
  </div>
  <div class="modal-footer">
    <button id="specialUserHelpClose" class="btn btn-primary" (click)="hideSpecialUserHelpModal()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #statusList>
  <ul class="list-unstyled">
    <li class="mb-1">{{ availableStatus }}</li>
    <li class="mb-1">{{ noFilingStatus }}</li>
    <li class="mb-1">{{ lockedStatus }}</li>
    <li class="mb-1">{{ pendingStatus }}</li>
  </ul>
</ng-template>
