/*
 * Copyright 2025 National Association of Insurance Commissioners
 */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InsdataLoadingSpinnerService } from '../../service/insdata-loading-spinner.service';

@Component( {
  selector: 'app-insdata-loading-spinner',
  templateUrl: './insdata-loading-spinner.component.html',
  styleUrls: [ './insdata-loading-spinner.component.scss' ],
} )
export class InsdataLoadingSpinnerComponent implements OnInit, OnDestroy {

  @ViewChild( 'loadingSpinnerModal', { static: false } ) loadingSpinnerModal: ModalDirective;

  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly insdataLoadingSpinnerService: InsdataLoadingSpinnerService,
    private readonly bsModalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.insdataLoadingSpinnerService.getLoadingSpinnerSubject().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( ( showLoadingSpinner ) => {
      if ( showLoadingSpinner ) {
        this.loadingSpinnerModal.show();
      } else {
        this.loadingSpinnerModal?.hide();
        if ( this.bsModalService.getModalsCount() === 0 ) {
          document.body.classList.remove( 'modal-open' );
        }
      }
    } );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
